import React from 'react'
import { ItemsMap } from '../../petal-common/Models/Generic'
import supportedLanguages from '../../petal-common/supportedLanguages'
import CTextField from '../CTextField'

type Props = {
	title?: string
	value?: ItemsMap<string>
	multiline?: boolean
	onChangeValue: (value: ItemsMap<string>) => void
}

const MultiLangInput = (props: Props) => {
	return (
		<div>
			{props.title && <div style={{ margin: "20px 0px 10px 0px" }}>{props.title}</div>}
			{
				supportedLanguages.map((item, i) => (
					<div style={{ marginTop: i == 0 ? undefined : 10, alignSelf: 'stretch' }} key={item.code}>
						<CTextField multiline={props.multiline} style={{ width: "100%" }} value={props.value?.[item.code] || ""} label={item.label} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							props.onChangeValue({
								...props.value,
								[item.code]: event.target.value
							})
						}} />
					</div>
				))
			}
		</div>
	)
}

export default MultiLangInput
