import * as AIIcons from "react-icons/ai";
import * as FIIcons from "react-icons/fi";
import * as CGIcons from "react-icons/cg"
import * as MDIcons from "react-icons/md";
import { IoMdMegaphone } from "react-icons/io";
import { colors } from "../../Global/colors";
import { webPath } from "../../Global/util";

export const sideBarIconProps = {
	size: 28,
	color: colors.white
}

export default [
	{
		label: "Dashboard",
		to: webPath("/admin/dashboard"),
		icon: <AIIcons.AiFillDashboard {...sideBarIconProps}/>
	},
	{
		label: "Utenti",
		to: webPath("/admin/users"),
		icon: <FIIcons.FiUsers {...sideBarIconProps}/>
	},
	{
		label: "Defunti",
		to: webPath("/admin/people"),
		icon: <CGIcons.CgCross {...sideBarIconProps}/>
	},
	{
		label: "Richieste di enti",
		to: webPath("/admin/pending-manager-requests"),
		icon: <MDIcons.MdManageAccounts {...sideBarIconProps}/>
	},
	{
		label: "Comunicazioni social",
		to: webPath("/admin/social-delete-wills"),
		icon: <IoMdMegaphone {...sideBarIconProps}/>
	},
	{
		label: "Segnalazioni",
		to: webPath("/admin/abuse-reports"),
		icon: <MDIcons.MdReportProblem {...sideBarIconProps}/>
	},
	{
		label: "Prodotti store",
		to: webPath("/admin/products"),
		icon: <MDIcons.MdShoppingCart {...sideBarIconProps}/>
	},
	{
		label: "Sfondi",
		to: webPath("/admin/wallpapers"),
		icon: <MDIcons.MdWallpaper {...sideBarIconProps}/>
	},
	{
		label: "Pagine pubbliche",
		to: webPath("/admin/public_pages"),
		icon: <MDIcons.MdPages {...sideBarIconProps}/>
	},
	{
		label: "Configurazione",
		to: webPath("/admin/config"),
		icon: <MDIcons.MdOutlineSettings {...sideBarIconProps}/>
	}
]