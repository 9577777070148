import { User } from "../petal-common/Models/User"

export const localSave = (key: string, value: any) => {
	if(value === null) {
		localStorage.removeItem(key)
	} else {
		if (typeof value == 'object') {
			value = JSON.stringify(value)
		}
	
		localStorage.setItem(key, value)
	}
}

export const localGet = (key: string) => {
	const value = localStorage.getItem(key)
	if(!value) return null
	try {
		return JSON.parse(value)
	} catch(e) {
		return value
	}
}

export const saveJWT = (token: string | null) => {
	return localSave('jwt', token)
}

export const loadJWT = () => {
	return localGet('jwt')
}

export const saveUser = (user: User | null) => {
	return localSave("user", user)
}

export const loadUser = (): User | null | undefined => {
	return localGet("user")
}