import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import CButton from '../../Components/CButton'
import CModal from '../../Components/CModal'
import CTable from '../../Components/CTable'
import CTextField from '../../Components/CTextField'
import PageLoading from '../../Components/PageLoading'
import { usePagination } from '../../Global/hooks'
import { container } from '../../Global/styles'
import { genericError, successToast } from '../../Global/toast'
import { DEFAULT_PAGE_SIZE, humanDate } from '../../Global/util'
import { approveManager, getUsers, rejectManager } from '../../Network/users'
import { translate } from '../../petal-client-common/Translation'
import { ItemsMap } from '../../petal-common/Models/Generic'
import { User, userDisplayName } from '../../petal-common/Models/User'

const UsersList = () => {

	const [loading, setLoading] = useState(true)
	const [data, setData] = useState<User[]>([])
	const [loadings, setLoadings] = useState<ItemsMap<{ approve?: boolean, reject?: boolean }>>({})
	const [selectedUser, setSelectedUser] = useState<User>()
	const [percentage, setPercentage] = useState<number>()
	
	const [revenuePercentageOpen, setRevenuePercentageOpen] = useState(false)
	
	const pagination = usePagination()

	useEffect(() => {
		fetchData(DEFAULT_PAGE_SIZE, 0)
	}, [])

	const fetchData = async (limit: number, offset: number) => {
		setLoading(true)
		try {
			const response = await getUsers({
				limit,
				offset: offset * limit,
				pending_managers: true,
				reverse_order: true
			})
			if (response.success) {
				setData(response.data?.users || [])
				pagination.setCount(response.data?.count || 0)
				pagination.setRows(response.data?.rows || 0)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setLoading(false)
	}

	const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
		pagination.setPage(newPage)
		fetchData(DEFAULT_PAGE_SIZE, newPage)
	}

	const onReject = async (user: User) => {
		setLoadings({
			[`${user.id}`]: { reject: true }
		})

		try {
			const response = await rejectManager(user.id)
			if (response.success) {
				fetchData(pagination.rows, pagination.page)
				successToast("Operazione completata")
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}

		setLoadings({})
	}

	const onApproveClick = (user: User) => {
		setPercentage(undefined)
		setSelectedUser(user)
		setRevenuePercentageOpen(true)
	}

	const onApprove = async () => {

		if(!selectedUser || percentage === undefined) return

		setLoadings({
			[`${selectedUser.id}`]: { approve: true }
		})

		try {
			const response = await approveManager(selectedUser.id, percentage)
			if (response.success) {
				fetchData(pagination.rows, pagination.page)
				setRevenuePercentageOpen(false)
				successToast("Operazione completata")
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}

		setLoadings({})
	}

	if(loading) {
		return <PageLoading/>
	}

	return (
		<div style={{ ...container }}>
			<CTable<User>
				data={data}
				headerLabels={[
					{ label: "" },
					{ label: translate('first_name') },
					{ label: translate('creation_date') },
					{ label: translate('email') },
					{ label: translate('city') },
					{ label: translate('country') },
					{ label: "" }
				]}
				rowCells={(user) => [
					{ content: <img style={{ height: 50, width: 50, objectFit: "contain" }} src={user.profile_image_thumb ? user.profile_image_thumb : '/assets/generic-placeholder.png'} /> },
					{ content: userDisplayName(user) },
					{ content: humanDate(user.created_at) },
					{ content: user.email },
					{ content: user.city },
					{ content: user.country?.name },
					{
						content: <div style={{ display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'flex-end' }}>
							<CButton loading={loadings?.[user.id]?.approve} style={{ width: 150 }} label={translate('approve')} onClick={() => onApproveClick(user)} />
							<CButton loading={loadings?.[user.id]?.reject} style={{ marginTop: 5, width: 150 }} label={translate('reject')} onClick={() => onReject(user)} />
						</div>
					}
				]}
				pagination={pagination}
				onPageChange={onPageChange}
			/>
			<CModal
				title={translate("percentage_applied")}
				open={revenuePercentageOpen}
				onClose={() => setRevenuePercentageOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setRevenuePercentageOpen(false) },
					{ label: "Salva", onPress: onApprove, loading: loadings[`${selectedUser?.id}`]?.approve, disabled: percentage === undefined }
				]}
			>
				<div>
					<div>{translate('percentage_message')}</div>
					<div style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
						<CTextField style={{ width: "100%", marginRight: 20 }} label={translate("percentage_applied")} type="number" value={percentage || undefined} onChange={e => setPercentage(+e.target.value)} />
						<div style={{ marginRight: 20 }}>%</div>
					</div>
				</div>
			</CModal>
			<Toaster />
		</div>
	)
}

export default UsersList
