import * as AIIcons from "react-icons/ai";
import * as CGIcons from "react-icons/cg"
import { colors } from "../../Global/colors";
import { webPath } from "../../Global/util";

export const sideBarIconProps = {
	size: 28,
	color: colors.white
}

export default [
	{
		label: "Dashboard",
		to: webPath("/manager/dashboard"),
		icon: <AIIcons.AiFillDashboard {...sideBarIconProps}/>
	},
	{
		label: "Defunti",
		to: webPath("/manager/people"),
		icon: <CGIcons.CgCross {...sideBarIconProps}/>
	}
]