import React, { useCallback, useState } from 'react'
import CButton from '../../Components/CButton'
import CTextField from '../../Components/CTextField'
import { colors } from '../../Global/colors'
import { login, saveSessionInfo } from '../../Network/auth'
import { translate } from '../../petal-client-common/Translation'
import toast, { Toaster } from 'react-hot-toast';
import { errorToast, genericError, successToast } from '../../Global/toast'
import { useNavigate } from 'react-router-dom'
import { webPath } from '../../Global/util'
import { isRoleApproved } from '../../petal-common/Models/User'

const LoginPage = () => {

	const navigate = useNavigate()
	
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [loading, setLoading] = useState(false)

	const onLoginPress = useCallback(async () => {
		setLoading(true)
		
		try {
			const response = await login(email, password)
			if(response.success) {
				const user = response.data?.user
				const jwt = response.data?.jwt
				if(user?.role == 'user') {
					errorToast(translate('user_type_not_supported'))
				} else if(user?.role == 'admin') {
					saveSessionInfo(user, jwt!)
					navigate(webPath('/admin/dashboard'))
				} else if(user?.role == 'manager') {
					saveSessionInfo(user!, jwt!)
					if(isRoleApproved(user!)) {
						navigate(webPath('/manager/dashboard'))
					} else {
						errorToast(translate("waiting_for_approve"))
					}
				}
			} else {
				genericError()
			}
		} catch(e: any) {
			console.log(e)
			let message
			if (e.code === 'auth/wrong-password') {
				message = translate('wrong_credentials')
			} else if (e.code === 'auth/invalid-email') {
				message = translate('invalid_email')
			} else if (e.code === 'auth/user-not-found') {
				message = translate('user_not_found')
			} else {
				message = translate('generic_error')
			}
			errorToast(message)
		}

		setLoading(false)
	}, [email, password])

	return (
		<div className='container'>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: 300, backgroundColor: colors.white, padding: 40, borderRadius: 5, marginTop: 100 }}>
				<div style={{ alignSelf: 'center', fontWeight: 'bold', fontSize: 20, marginBottom: 20 }}>{translate('login')}</div>
				<CTextField label={translate('email')} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					setEmail(event.target.value);
				}} />
				<CTextField type="password" label={translate('password')} style={{ marginTop: 10 }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					setPassword(event.target.value);
				}} />
				<CButton disabled={!email || !password} loading={loading} style={{ marginTop: 20 }} onClick={onLoginPress} label={translate('login')} />
			</div>
			<Toaster />
		</div>
	)
}

export default LoginPage

