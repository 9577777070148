import { http, loadUrl, multipart } from "."
import { PublicPage } from "../petal-common/Models/PublicPage"

type GetPublicPagesResponse = {
	public_pages: PublicPage[],
	count: number
	rows: number
}
type GetPublicPagesParams = {
	limit: number
	offset: number
}
export const getPublicPages = (params: GetPublicPagesParams) => {
	return http<GetPublicPagesResponse>(loadUrl("public_pages", {
		limit: params.limit,
		offset: params.offset
	}))
}

type PublicPageResponse = {
	public_page: PublicPage
}
export const createPublicPage = (data: Partial<PublicPage>) => {
	return http<PublicPageResponse>("public_pages", "POST", data)
}

export const editPublicPage = (page_id: number, data: Partial<PublicPage>) => {
	return http<PublicPageResponse>(`public_pages/${page_id}`, "PUT", data)
}

export const updatePublicPagePhoto = (page_id: number, image: File) => {
	return multipart(`public_pages/${page_id}/media`, "PUT", "media", image)
}

export const deletePublicPage = (page_id: number) => {
	return http<any>(`public_pages/${page_id}`, "DELETE")
}