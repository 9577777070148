import { MenuList } from '@mui/material'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { colors } from '../../Global/colors'
import { logout } from '../../Network/auth'
import * as MDIcons from "react-icons/md"
import "./style.css"
import { sideBarIconProps } from '../../Pages/Admin/sidebarItems'
import { ENTRY_POINT } from '../../Global/util'

export type RouteMenuEntry = {
	label: string,
	to: string,
	icon?: any
}

type Props = {
	items: RouteMenuEntry[]
}

const Sidebar = (props: Props) => {

	const navigate = useNavigate()

	return (
		<div className='gradient-background' style={{ width: 300, backgroundColor: colors.sidebar, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
			<img className='header-icon' src='/assets/icona-petal.png' />
			<MenuList style={{ flex: 1 }}>
				{props.items.map((item, index) => (
					<div id="sidebar-item" key={index}>
						<NavLink className="sidebar-item-container" to={item.to}
							style={({ isActive }) => ({
								...(isActive ? {
									backgroundColor: "var(--sidebar-selected-item)"
								} : undefined)
							})
							}
						>
							<div style={{
								color: colors.lightGrey,
								fontWeight: 'bold',
								fontSize: 18,
								display: 'flex'
							}}
								onClick={() => navigate(item.to)}
							>
								<div style={{ marginRight: 15 }}>
									{item.icon}
								</div>
								<div style={{ marginTop: 3 }}>
									{item.label}
								</div>
							</div>
						</NavLink>
					</div>
				))}
			</MenuList>
			<div id="sidebar-item" style={{ cursor: 'pointer', marginBottom: 15 }} onClick={() => {
				logout()
				navigate(ENTRY_POINT)
			}}>
				<div className="sidebar-item-container">
					<div
						style={{
							color: colors.lightGrey,
							fontWeight: 'bold',
							fontSize: 18,
							display: 'flex'
						}}
					>
						<div style={{ marginRight: 15 }}>
							<MDIcons.MdLogout {...sideBarIconProps} />
						</div>
						<div style={{ marginTop: 3 }}>
							Logout
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Sidebar
