import React, { ReactNode } from 'react'
import { IconButton as _IconButton, IconButtonProps } from '@mui/material';
import * as MDIcons from 'react-icons/md'

type Props = {
	icon: ReactNode
} & IconButtonProps

const IconButton = (props: Props) => {
	return (
		<_IconButton {...props}>
			{props.icon}
		</_IconButton>
	)
}

export default IconButton
