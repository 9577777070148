import React, { useState } from 'react'
import { ItemsMap } from '../../petal-common/Models/Generic'
import supportedLanguages from '../../petal-common/supportedLanguages'
import CTextField from '../CTextField'
import { Person } from '../../petal-common/Models/Person'
import { User, userDisplayName } from '../../petal-common/Models/User'
import CModal from '../CModal'
import SelectSearch, { SelectSearchOption, SelectedOption, SelectedOptionValue } from 'react-select-search'
import { getUsers } from '../../Network/users'
import './style.css'
import { relative } from 'path'
import { setRelative } from '../../Network/people'
import { genericError } from '../../Global/toast'
type Props = {
	person: Person | null,
    relative: User | null, 
    open: boolean,
    onFinish: () => void
}

const AssignRelative = (props: Props) => {
    var [loading, setLoading] = useState(false)
    var defaultUsers = props.relative != null ? [props.relative!!] : []
    var [users, setUsers] = useState<User[]>(defaultUsers)
    var [selectedUser, setSelectedUser] = useState<User | null>(props.relative)
    

    const onSearchUser = async (query: string): Promise<User[]> => {
        try {
            const response = await getUsers({
                limit: 10,
                query: query
            })
            if (response.success) {
                return response.data?.users || []
            }
        } catch (e) {
            console.error(e)
        }
        return []
    }
	const onSubmitRelative = async (person: Person, user: User): Promise<boolean> => {
        try {
            const response = await setRelative({
                user_id: user.id,
                person_id: person.id
            })
            if(response.error) {
                genericError()
                return false
            }
            console.log(response)
        } catch (e) {
            genericError()
            console.error(e)
            return false
        }
        return true
	}

    const map = (user: User): SelectSearchOption => {
        return {key: user.id + "", name: userDisplayName(user) ?? "", value: user.id}
    }
    const mapAll = (users: User[]): SelectSearchOption[] => {
        return users.map(map)
    }

    const onSearch = async (query : string): Promise<SelectSearchOption[]> => {
        const users = await onSearchUser(query)
        setUsers(users)
        return mapAll(users)
    }
    const onSelect = (name : SelectedOptionValue | SelectedOptionValue[], option: SelectedOption | SelectedOption[]) => {
        const id = (option as SelectedOption).key
        const user = users.find(v => v.id == id) ?? null
        setSelectedUser(user)
    }
    const onSubmit = () => {
        setLoading(true)
        onSubmitRelative(props.person!!, selectedUser!!)
            .then((result) => {
                setLoading(false)
                if(result) props.onFinish()
            })
            .catch(() => setLoading(false))
    }

    const onBack = () => {
        props.onFinish()
    }
    const buttonDisabled = selectedUser == null
	return (
		<CModal title="Seleziona l'amministratore" open={props.open} actions={[{label: "Indietro", disabled: false, loading: false, onPress: onBack}, {label: "Assegna", disabled: buttonDisabled, loading: loading, onPress: onSubmit}]}>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems:"center", flex: 1, height: 400 }}>
                <p>Seleziona dall'elenco, o ricerca. Potrai assegnare al massimo un amministratore per persona</p>
                <SelectSearch multiple={false} value={selectedUser?.id.toString()} search={true} emptyMessage={"Nessun utente trovato"} getOptions={onSearch} onChange={onSelect} options={mapAll(users)} placeholder="Cerca..." />
            </div>

        </CModal>
	)
}

export default AssignRelative
