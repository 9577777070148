import React from 'react'
import { colors } from '../../Global/colors'

const Line = () => {
	return (
		<div style={{ height: '100%', width: "1px", backgroundColor: colors.lightGrey, margin: "0px 20px" }} />
	)
}

export default Line
