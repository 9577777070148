import firebaseConfig from '../firebase.config';
import { initializeApp } from 'firebase/app'
import {
	getAuth,
	signInWithEmailAndPassword,
	signOut,
} from "firebase/auth";
import { User, UserRole } from '../petal-common/Models/User';
import { http } from '.';
import { saveJWT, saveUser } from '../Services/local.storage';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const login = async (email: string, password: string) => {
	const response = await signInWithEmailAndPassword(auth, email, password);
	const auth_token = await response.user.getIdToken()
	return userFromToken(email, auth_token)
}

export type UserFromTokenResponse = {
	email_verified: boolean
	jwt: string,
	user: User
}
export const userFromToken = async (email: string, auth_token: string, role?: UserRole, user_data?: Partial<User>) => {
	const data = await http<UserFromTokenResponse>('users', 'POST', {
		email,
		token: auth_token,
		role,
		user_data
	})
	return data
}

export const saveSessionInfo = (user: User, jwt: string) => {
	saveJWT(jwt || "")
	saveUser(user)
}

export const logout = () => {
  signOut(auth);
	saveUser(null)
	saveJWT(null)
};