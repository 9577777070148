import { http, multipart } from "."
import { Wallpaper } from "../petal-common/Models/Wallpaper"

export type WallpapersResponse = {
	wallpapers: Wallpaper[]
}
export const getWallpapers = () => {
	return http<WallpapersResponse>("wallpapers")
}

export type WallpaperResponse = {
	wallpaper: Wallpaper
}
export const createWallpaper = (data: Partial<Wallpaper>) => {
	return http<WallpaperResponse>(`wallpapers`, "POST", data)
}

export const uploadWallpaperPhoto = (wallpaper_id: number, image: File) => {
	return multipart<WallpaperResponse>(`wallpapers/${wallpaper_id}/media`, "PUT", "media", image)
}

export const updateWallpaper = (wallpaper_id: number, data: Partial<Wallpaper>) => {
	return http<WallpaperResponse>(`wallpapers/${wallpaper_id}`, "PUT", data)
}

export const deleteWallpaper = (wallpaper_id: number) => {
	return http<WallpaperResponse>(`wallpapers/${wallpaper_id}`, "DELETE")
}

export const reorderWallpapers = (newOrder: { wallpaper_id: number, order: number }[]) => {
	return http<WallpapersResponse>(`/wallpapers/reorder`, "PUT", {
		new_order: newOrder
	})
}