import axios from "axios"
import { loadJWT } from "../Services/local.storage"
import config from "./config"

export type HTTPMethod = 'POST' | 'GET' | 'PUT' | 'DELETE'

export type RequestResponse<T> = {
	success: boolean,
	auth_expired?: boolean
	user_locked?: boolean
	data: T | null
	error?: string
}

export type JWT = string | null

export const http = async <T extends unknown>(url: string, method: HTTPMethod = 'GET', body?: any): Promise<RequestResponse<T>> => {

	try {
		const requestOptions = {
			headers: {
				'Auth': 'Bearer ' + loadJWT(),
				'Content-Type': 'application/json',
			},
			method: method,
			body: body ? JSON.stringify(body) : undefined
		}
		console.log("REQUESTING TO:", config.api_url + url)
		const response = await fetch(config.api_url + url, requestOptions)
		if (response.status == 403) {
			return {
				success: false,
				auth_expired: true,
				data: null
			}
		} else if (response.status == 423) {
			return {
				success: false,
				user_locked: true,
				data: null
			}
		} else {
			const data = await response.json()
			data.success = response.status >= 200 && response.status < 300
			return data
		}

	} catch (e: any) {
		console.log(e)
		return {
			success: false,
			data: null,
			error: e
		}
	}
}

export const loadUrl = (url: string, queryParams?: any) => {
	let result = url
	if (queryParams) {
		const stringifiedParams = objToQueryString(queryParams)
		result += ('?' + stringifiedParams)
	}

	return result
}

const objToQueryString = (obj: any) => {
	const keyValuePairs = [];
	for (const key in obj) {
		if (obj[key] != undefined || obj[key] != null) {
			let val = obj[key]
			
			if(Array.isArray(val)) {
				val = obj[key].join(',')
			}

			keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(val));
		}
	}
	return keyValuePairs.join('&');
}

export const multipart = async <T extends unknown>(url: string, method: HTTPMethod, file_key: string, file: any, data?: any): Promise<RequestResponse<T>> => {

	const formData = new FormData();
	formData.append(file_key || 'media', file, file.name);

	if(data) {
		Object.entries(data).forEach(([key, value]: [string, any]) => {
			formData.append(key, value)
		})
	}

	try {
		const response = await axios.put<RequestResponse<T>>(config.api_url + url, formData, {
			headers: {
				'Auth': 'Bearer ' + loadJWT(),
			}
		})

		if (response.status == 403) {
			return {
				success: false,
				auth_expired: true,
				data: null
			}
		} else if (response.status == 423) {
			return {
				success: false,
				user_locked: true,
				data: null
			}
		} else {
			const data = response.data
			data.success = response.status >= 200 && response.status < 300
			return data
		}
	} catch (e: any) {
		console.log(e)
		return {
			success: false,
			data: null,
			error: e
		}
	}
}