import { Card } from '@mui/material'
import React, { CSSProperties, ReactNode } from 'react'

type Props = {
	variant?: "elevation" | "outlined" | undefined
	children: ReactNode
	style?: CSSProperties
}

const CCard = (props: Props) => {
	return (
		<Card variant={props.variant || 'outlined'} style={{ margin: 10, padding: 10, display: 'flex', ...props.style }}>
			{props.children}
		</Card>
	)
}

export default CCard
