import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'

export type SegmentedItem = {
	label: string
	value: string
}

type Props = {
	items: SegmentedItem[]
	value: string
	minItemWidth?: number
	onChange: (val: string) => void
}

const SegmentedControl = (props: Props) => {
	return (
		<div>
			<ToggleButtonGroup
				orientation="horizontal"
				value={props.value}
				exclusive
				onChange={(e, next) => props.onChange(next)}
			>
				{
					props.items.map((item, i) => (
						<ToggleButton
							key={i}
							value={item.value}
						>
							<div style={{ minWidth: props.minItemWidth || 200, fontWeight: 'bold' }}>
								{item.label}
							</div>
						</ToggleButton>
					))
				}
			</ToggleButtonGroup>
		</div>
	)
}

export default SegmentedControl
