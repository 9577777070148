import { Button, ButtonProps, CircularProgress } from '@mui/material'
import React from 'react'

type Props = ButtonProps & {
	label: string
	loading?: boolean
}

const CButton = (props: Props) => {

	const { loading } = props

	return (
		<Button variant="outlined" { ...props }>
			{props.label}
			{loading && <div style={{ marginLeft: 10 }}><CircularProgress size={12}/></div>}
		</Button>
	)
}

export default CButton
