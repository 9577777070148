export default {
	'login': "Accedi",
	'logout': "Esci",
	'register': "Iscriviti",
	'email': "Email",
	'recover_password': "Recupera password",
	'recover_your_password': "Recupera la tua password",
	'recover_password_instructions': "Inserisci la tua email così possiamo inviarti le istruzioni per recuperare la tua password",
	'password': "Password",
	'password_confirm': "Conferma password",
	'register_to_petal': "Iscriviti a Petal",
	'register_manager': "Iscrivi la tua agenzia",
	'register_description': "Crea un account su Petal e passa a trovare tutti i tuoi cari.\nCon Petal puoi conservare tutte le tue volontà in vita.",
	'register_description_manager': "Crea un account su Petal alla tua agenzia. In questo modo potrai collaborare con noi",
	'register_account_description': "Inserisci le seguenti informazioni per poter creare il tuo account all'interno di Petal.",
	'welcome_to_petal': "Ti diamo il benvenuto su Petal",
	'login_description': "Inserisci la tua mail di registrazione e la password",
	'tos_privacy_title': "Accordo di licenza",
	'tos_privacy_message': "All'interno di Petal ci teniamo molto ad un comportamento adeguato da parte della community e alla protezione della privacy degli utenti.",
	'sign_up_with_apple': "Iscriviti con Apple",
	'sign_up_with_facebook': "Iscriviti con Facebook",
	'sign_up_with_google': "Iscriviti con Google",
	'sign_up_with_email': "Iscriviti con email",
	'sign_in_with_apple': "Accedi con Apple",
	'sign_in_with_facebook': "Accedi con Facebook",
	'sign_in_with_google': "Accedi con Google",
	'sign_in_with_email': "Accedi con email",
	'or_login_with': "Oppure accedi tramite social network",
	'continue': "Continua",
	'forgot_password': "Password dimenticata",
	'reset_password_sent_title': "Email inviata",
	'reset_password_sent_message': "Ti abbiamo inviato un'email con le istruzioni per recuperare la tua password",
	'invalid_data': "Informazioni errate",
	'password_not_matching': "Le password che hai inserito non corrispondono",
	'error': "Operazione non riuscita",
	'email_in_use': "L'indirizzo email inserito è già in uso. Se non ricordi la tua password prova a recuperarla",
	'invalid_email': "L'indirizzo email inserito non è valido. Riprova con un altro indirizzo",
	'invalid_info': "Le informazioni inserite non sono valide. Riprova",
	'missing_data': "Dati mancanti",
	'insert_required_fields': "Inserisci tutti i campi contrassegnati come obbligatori (*)",
	'weak_password': "La password inserita è troppo debole. Riprova con una password più complessa e che contenga almeno 6 caratteri",
	'wrong_credentials': "Le credenziali che hai fornito non sono corrette. Riprova",
	'user_not_found': "Non abbiamo trovato nessuna corrispondenza con l'email inserita. Prova con un altro indirizzo",
	'generic_error': "Si è verificato un errore, riprova",
	'select_account_type': "Seleziona il tipo di account",
	'user': "Utente",
	'manager': "Gestore",
	'edit_profile': "Modifica profilo",
	'first_name': "Nome",
	'last_name': "Cognome",
	'gender': "Genere",
	'birth_date': "Data di nascita",
	'day': 'Giorno',
	'month': 'Mese',
	'year': 'Anno',
	'residence': "Residenza",
	'address': "Indirizzo",
	'house_number': 'Numero civico',
	'city': "Città",
	'post_code': "Codice postale",
	'male': "Uomo",
	'female': "Donna",
	'other': "Altro",
	'save': "Salva",
	'select_country': "Seleziona paese",
	'select': "Seleziona",
	'not_inserted': "Non inserito",
	'country': "Nazione",
	'tos': "Termini e condizioni",
	'privacy_policy': "Privacy policy",
	'tos_declare': "Dichiaro di aver letto e accetto i",
	'privacy_declare': "Dichiaro di aver letto e accetto la",
	'home': "Home",
	'search': "Cerca",
	'my_people': "I miei cari",
	'favourites': "Preferiti",
	'testament': "Testamento",
	'add': "Aggiungi",
	'death_day': "Data di morte",
	'cemetery': "Cimitero",
	'cemetery_placeholder': "Città / paese",
	'no_my_people': "Non hai ancora aggiunto nessun tuo caro",
	'no_favourites': "Non hai ancora aggiunto nessuno ai preferiti",
	'no_diary': "Non hai ancora niente da visualizzare nel diario",
	'search_placeholder': "Cerca...",
	'no_results': "Nessun risultato",
	'niche_in_gallery': 'Loculo in galleria',
	'niche_in_family_tomb': 'Loculo in tomba di famiglia',
	'niche_cinerary_urn': 'Loculo urna cineraria/ossario',
	'ground_tomb': 'Tomba in terreno',
	'common_ossary': 'Ossario comune',
	'urn_in_family': 'Urna in affido ai familiari',
	'spread_common_cinerary': "Dispersione in cinerario comune",
	'spread_garden': "Dispersione in giardino delle rimembranze",
	'spread_nature': "Dispersione in natura",
	'tomb_type': "Tipologia di tomba",
	'add_person': "Aggiungi un tuo caro",
	'edit': "Modifica",
	'delete': "Elimina",
	'confirm_delete': "Conferma eliminazione",
	'delete_person_warning': "Sei sicuro di voler rimuovere il tuo caro da Petal? Quest'operazione non può essere annullata.",
	'select_gender': "Seleziona genere",
	'cancel': "Annulla",
	'if_manager': "Se sei un'agenzia",
	'ok': "Ok",
	'tax_id': "Partita iva",
	'iban': "IBAN",
	'store': "Store",
	'flowers': "Fiori",
	'leds': "Led",
	'gifts': "Regali",
	'messages': "Messaggi",
	'photos': "Foto",
	'photo': "Foto",
	'videos': "Video",
	'video': "Video",
	'backgrounds': "Sfondi",
	'write_a_message': "Scrivi un messaggio per il tuo caro",
	'send_a_photo': "Invia una foto al tuo caro",
	'send_a_video': "Invia un video al tuo caro",
	'send_a_audio': "Invia un audio al tuo caro",
	'send': 'Invia',
	'write_your_message': "Scrivi il tuo messaggio...",
	'missing_authorization': "Autorizzazione mancante",
	'missing_authorization_camera': "Non hai fornito l'autorizzazione ad accedere alla fotocamera. Abbiamo bisogno di questo permesso per completare l'operazione",
	'missing_authorization_generic': "Non hai fornito le autorizzazioni necessarie alla funzionalità che stai cercando di utilizzare. Vai nelle impostazioni e fornisci le autorizzazioni necessarie a Petal, dopo di che riprova.",
	'go_to_settings': "Vai alle impostazioni",
	'add_description': "Aggiungi descrizione",
	'action_sent': "Omaggio inviato",
	'action_received': "Il tuo caro ha ricevuto il tuo omaggio!",
	'return_to_person': "Ritorna al tuo caro",
	'uploading': "Upload in corso",
	'comment': "Commento",
	'comments': "Commenti",
	'show_all': "Vedi tutto",
	'tomb_info': "Informazioni tomba",
	'write_comment': "Scrivi un commento...",
	'no_comments': "Non ci sono commenti da visualizzare",
	'share': "Condividi",
	'edit_email': "Modifica email",
	'sure_to_edit_mail': "Sei sicuro di voler modificare la tua email? L'email viene utilizzata per effettuare il login",
	'insert_new_email': "Inserisci qui la tua nuova email",
	'insert_password': "Inserisci la password del tuo account",
	'edit_password': "Modifica password",
	'old_password': "Vecchia password",
	'new_password': "Nuova password",
	'share_quote_message': "Ho inviato un messaggio a {{name}}, grazie a Petal, scaricalo anche tu e vallo a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_photo': "Ho inviato una foto a {{name}}, grazie a Petal, scaricalo anche tu e valla a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_video': "Ho inviato un video a {{name}}, grazie a Petal, scaricalo anche tu e vallo a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_flower': "Ho deposto un fiore a {{name}}, grazie a Petal, scaricalo anche tu e vallo a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_leds': "Ho acceso un led a {{name}}, grazie a Petal, scaricalo anche tu e valla a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_gifts': "Ho fatto un regalo a {{name}}, grazie a Petal, scaricalo anche tu e valla a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_comment': "Ho commentato un omaggio a {{name}}, grazie a Petal, scaricalo anche tu e vallo a vedere. Inserisci subito i tuoi cari, saranno sempre fra le tue mani, e potrai ricordarli in qualsiasi ora del giorno e notte, e da ovunque ti trovi, in modo condiviso e con un solo click!.\nDownload -> https://app.petalsocial.com/download",
	'group_tribute': "Omaggio di gruppo",
	'all_people': "Tutti",
	'all': "Tutti",
	'men': "Uomini",
	'women': "Donne",
	'tribute_for': "Omaggio a: {{group}}",
	'delete_account': "Elimina il mio account",
	'sure_to_delete_account': "Sei sicuro di voler eliminare il tuo account?",
	'delete_account_description': "Se decidi di eliminare il tuo account, tutti i dati relativi ad esso verranno eliminati dai nostri sistemi. L'operazione è irreversibile.",
	'insert_password_to_procede': "Per procedere inserisci la password del tuo account",
	'assign_activators': "Assegnazione attivatori",
	'activator_invite_acceptance': "Accettazione volontà di altre persone",
	'prepare_your_later': "Prepara il tuo dopo",
	'communication_to_social': "Comunicazioni ai social",
	'audio': "Audio",
	'audios': "Audio",
	'activators': "Attivatori",
	'will_acceptance': "Accettazione volontà",
	'activators_explanation': "Attenzione prima di iniziare associa al tuo profilo le 5 persone che in caso della tua scomparsa dovranno confermare la tua stessa. Queste persone riceveranno una mail dove dovranno accettare questa tua volontà. La tua scomparsa sarà confermata solo quando almeno 3 di queste 5 persone entreranno in Petal per confermarla. Petal chiederà agli attivatori con il grado di parentela più stretta il certificato di morte, per confermare la veracità della scomparsa.\nIn qualsiasi momento puoi annullare questa azione dal tuo profilo.",
	'email_preview': "Anteprima email",
	'activators_assignment': "Assegnazione attivatori",
	'search_in_petal': "Cerca in Petal",
	'search_in_petal_or_email': "Cerca in Petal o inserisci l'email",
	'select_family_bond': "Seleziona il legame",
	'edit_family_bond': "Modifica il legame",
	'parent': "Genitore",
	'sibiling': "Fratello/sorella",
	'friend': "Amico/a",
	'relative': "Parente",
	'son': "Figlio/figlia",
	'remove': "Rimuovi",
	'camera': "Fotocamera",
	'library': "Libreria",
	'remove_photo': "Rimuovi foto",
	'refresh': "Aggiorna",
	'invite_with_email': "Invita tramite email",
	'invite_with_email_description': "Invita delle persone ad iscriversi a Petal, in questo modo potrai aggiungere come tuoi attivatori. Per invitarle inserisci qui le loro email, separate da virgola.",
	'invite': "Invita",
	'insert_email': "Inserisci email",
	'activator_requests': "Richieste di attivazione",
	'activator_request': "Richiesta di attivazione",
	'activator_for': "Attivatore per",
	'activator_request_rejected': "Richiesta di attivazione rifiutata",
	'got_it': "Capito",
	'activator_requests_explanation': "Un tuo conoscente ti ha nominato per dichiarare la sua scomparsa in caso di morte. Accettando questa richiesta tu insieme ad altre quattro persone nominate accerterete la morte di un vostro caro. Il richiedente riceverà una mail con la vostra accettazione.",
	'change_choice': "Modifica scelta",
	'accept_request': "Accetta richiesta",
	'reject_request': "Rifiuta richiesta",
	'pending': "In sospeso",
	'accepted': "Accettate",
	'rejected': "Rifiutate",
	'dead_process': "Notifica scomparsa",
	'dead_process_warning_title': "Attenzione, sei sicuro di voler notificare la scomparsa di {{name}}?",
	'dead_process_explanation': "Se avvierai il processo di notifica della scomparsa, tutti gli altri attivatori riceveranno una notifica.",
	'confirm_death': "Conferma scomparsa",
	'dead_process_in_progress': "Conferma scomparsa in corso",
	'your_death_notified_title': "È stata notificata la tua scomparsa",
	'your_death_notified_explanation': "Alcuni dei tuoi attivatori hanno notificato la tua scomparsa, se si tratta di un'informazione errata puoi annullare il processo con il pulsante qui sotto",
	'cancel_death': "Annulla scomparsa",
	'death_confirmed': "Scomparsa confermata",
	'max_activators_reached_title': "Numero massimo raggiunto",
	'max_activators_reached_message': "Il numero di attivatori massimo che puoi aggiungere è {{value}}.",
	'cant_complete_operation': "Impossibile completare l'operazione",
	'same_email': "Non puoi aggiungere tra gli attivatori un utente con la tua stessa email.",
	'received_plural': "Ricevuti",
	'sent_plural': "Inviati",
	'received_plural_female': "Ricevute",
	'sent_plural_female': "Inviate",
	'leave_a_message': "Lascia un messaggio",
	'leave_a_photo': "Lascia una foto",
	'leave_a_video': "Lascia un video",
	'leave_a_audio': "Lascia un audio",
	'select_recipient': "Scegli destinatario",
	'message': "Messaggio",
	'message_from': "Messaggio da",
	'photo_from': "Foto da",
	'video_from': "Video da",
	'audio_from': "Audio da",
	'testament_message_explanation': "Potrai leggere questo messaggio solamente alla scomparsa del donatore",
	'testament_photo_explanation': "Potrai visualizzare questa foto solamente alla scomparsa del donatore",
	'testament_video_explanation': "Potrai visualizzare questo video solamente alla scomparsa del donatore",
	'testament_audio_explanation': "Potrai ascoltare questo audio solamente alla scomparsa del donatore",
	'testament_messages_guide': "Scrivi messaggi, assegnali alle persone che desideri. Al momento della tua scomparsa gli eredi riceveranno una comunicazione che nel tuo profilo troveranno un messaggio privato da parte tua. In questa sezione vedi le notifiche di messaggi inviati a te che in un futuro potrai leggere.",
	'testament_audio_guide': "Registra messaggi audio, assegnali alle persone che desideri. Al momento della tua scomparsa gli eredi riceveranno una comunicazione di un messaggio audio privato da parte tua. In questa sezione vedi le notifiche di messaggi inviati a te che in un futuro potrai leggere.",
	'testament_video_guide': "Registra messaggi video, assegnali alle persone che desideri. Al momento della tua scomparsa gli eredi riceveranno una comunicazione di un messaggio audio privato da parte tua. In questa sezione vedi le notifiche di messaggi inviati a te che in un futuro potrai leggere.",
	'testament_photo_guide': "Carica delle foto, assegnale alle persone che desideri. Al momento della tua scomparsa gli eredi riceveranno una comunicazione che hai lasciato delle foto. In questa sezione vedi le notifiche di messaggi inviati a te che in un futuro potrai leggere.",
	'record': "Registra",
	'stop_record': "Stop",
	'play_record': "Riproduci",
	'pause_record': "Pausa",
	'view': "Visualizza",
	'delete_testament_item_warning': "Sei sicuro di voler eliminare questo lascito? Questa operazione non può essere annullata.",
	'delete_my_later_item_warning': "Sei sicuro di voler eliminare questo messaggio audio? Questa operazione non può essere annullata.",
	'message_to': "Messaggio a",
	'photo_to': "Foto a",
	'video_to': "Video a",
	'audio_to': "Audio a",
	'my_later': "My later",
	'enabled_my_later': 'Abilita My later e pubblica il mio profilo nel momento della mia scomparsa',
	'add_social': "Aggiungi social",
	'social_name': "Nome del social",
	'my_later_explanation': "Prepara il tuo profilo per quando non ci sarai più, lascia un messaggio vocale a tutte le persone che passeranno a farti visita. Il tuo Later si attiverà quando almeno 3 delle 5 persone che hai scelto nella sezione attivatori confermeranno la tua scomparsa. Nel caso non fosse vero potrai annullare in qualsiasi momento l’attivazione.",
	'audio_messages': "Messaggi audio",
	'profile_management': "Gestione profilo",
	'profile_manager': "Amministratore profilo",
	'my_later_audios_explanation': "Registra un audio, può essere assegnato a una persona oppure aperto a tutti. Tutte le volte che qualcuno passerà a trovarti verrà riprodotto in automatico",
	'leave_a_audio_in_your_profile': "Lascia un audio sul tuo profilo",
	'cant_continue_because_you_are_dead': "Non puoi continuare perchè il tuo profilo è registrato come deceduto, pertanto queste informazioni non possono più essere modificate",
	'my_later_admin_explanation': "Nomina qualcuno che possa modificare il tuo profilo quando non ci sarai più. Nel caso non venisse nominato nessuno, nessun utente potrà modificarlo.",
	'select_user_or_email': "Seleziona utente o email",
	'social_delete_will_guide': "Registra un video con le tue volontà in caso di scomparsa. Seleziona i social a cui vuoi che venga comunicato. Petal si prenderà cura di segnalare questa tua volontà nel momento della tua scomparsa.",
	'user_type_not_supported': "Tipologia di utente non supportata",
	'dashboard': "Dashboard",
	'users': "Utenti",
	'people': "Defunti",
	'unlock': "Sblocca",
	'block': "Blocca",
	'account_type': "Tipo di account",
	'creation_date': "Data di creazione",
	'percentage_applied': "Percentuale applicata",
	'creator': "Creatore",
	'approve': "Approva",
	'reject': "Rifiuta",
	'percentage_message': "Inserisci la percentuale che il gestore incasserà dagli acquisti destinati ai defunti che gestisce.",
	'flowers_top_disclaimer': "I fiori durano {{duration}} giorni e saranno visibili sul profilo del tuo caro fino a che non appassiranno",
	'leds_top_disclaimer': "I led durano {{duration}} giorni e saranno visibili sul profilo del tuo caro fino a che non si spegneranno",
	'gifts_top_disclaimer': "I regali durano {{duration}} giorni e saranno visibili sul profilo del tuo caro",
	'complete_purchase': "Completa acquisto",
	'send_to': "Invia a {{name}}",
	'free': "Free",
	'wallpapers_shop': "Shop sfondi",
	'active': "Attivo",
	'what_to_say': "Cosa devi dire",
	'social_delete_speech_1': "Io ",
	'social_delete_speech_2': "“nome e cognome”\n",
	'social_delete_speech_3': "nato a ",
	'social_delete_speech_4': "“città” “stato”\n",
	'social_delete_speech_5': "in ",
	'social_delete_speech_6': "“data di nascita”.\n",
	'social_delete_speech_7': "Invio questo video agli Amministratori di",
	'social_delete_speech_8': "“nome social” ",
	'social_delete_speech_9': "tramite Petal Social, per informarli della mia morte avvenuta, invitandoli a chiudere il mio account aperto con queste coordinate:\n",
	'social_delete_speech_10': "user name __________\npassword __________.\n",
	'social_delete_speech_11': "L'autenticità del video potete verificarla osservando le funzioni di petal social e come si arriva alla creazione del medesimo.\nRingrazio convinto di avere così contribuito, con documento certificato ed idoneo al drenaggio dei tantissimi account ancora aperti di persone defunte.",
	'retry': "Riprova",
	'waiting_for_approve': "Attesa approvazione",
	'manager_waiting_text': "Stiamo esaminando la tua richiesta di iscrizione. Attendi che il nostro staff approvi il tuo account",
	'screenshot_disclaimer': "Fai uno screenshot a questa pagina così puoi stampare questo testo e poi lo puoi leggere mentre registri il video",
	'item_saved_in_gallery': "Elemento salvato nella galleria",
	'paid': "A pagamento",
	'gain': "Guadagno",
	'gross': "Lordo",
	'net_from_stores': "Netto dagli store",
	'net': "Netto",
	
	'january': "Gennaio",
	'february': "Febbraio",
	'march': "Marzo",
	'april': "Aprile",
	'may': "Maggio",
	'june': "Giugno",
	'july': "Luglio",
	'august': "Agosto",
	'september': "Settembre",
	'october': "Ottobre",
	'november': "Novembre",
	'december': "Dicembre",

	'start': 'Inizia',

	'tutorial_item_welcome_title': 'Benvenuto su Petal.',
	'tutorial_item_welcome_message': 'Petal Social è l\'ambiente ideale per ricordare, collettivamente, i cari che sono passati a miglior vita.',
	'tutorial_item_home_title': 'La tua Home.',
	'tutorial_item_home_message': 'Rimani aggiornato su qualsiasi azione mandata a un tuo defunto nella sezione Home.',
	'tutorial_item_search_title': 'Ricerca.',
	'tutorial_item_search_message': 'Ricerca le persone defunte nel nostro database.',
	'tutorial_item_add_deceased_title': 'Aggiungi i tuoi defunti.',
	'tutorial_item_add_deceased_message': 'Inserisci nell\'app le informazioni dei tuoi cari defunti. Creerai un posto speciale per ricordarli.',
	'tutorial_item_favourites_title': 'Preferiti.',
	'tutorial_item_favourites_message': 'Contrassegna un defunto come \'Preferito\' per avere un accesso più semplice alla sua pagina tramite questa sezione.',
	'tutorial_item_profile_title': 'Il tuo profilo.',
	'tutorial_item_profile_message': 'Configura il tuo profilo e gli altri servizi associati, come MyLater.',

	'notifications': 'Notifiche',
	'no_notifications': 'Nessuna notifica',
	"read_all": "Leggi tutto",

	'delete_action': "Elimina",
	'report_abuse': "Segnala abuso",
	'tell_us_why_reporting_action': "Spiegaci il motivo per cui stai segnalando questo post",
	'tell_us_why_reporting_comment': "Spiegaci il motivo per cui stai segnalando questo commento",
	'report_abuse_received': "Grazie per il contributo, analizzermo la tua segnalazione",
	'report_entity': "Elemento segnalato",
	'reporter': "Segnalatore",
	'report_date': "Data di segnalazione",
	'post': "Post",
	'delete_action_warning': "Sei sicuro di voler eliminare questo post? Questa operazione non può essere annullata.",
	'delete_comment_warning': "Sei sicuro di voler eliminare questo commento? Questa operazione non può essere annullata.",

	'name': "Nome",
	'description': "Descrizione",
	'pinned': "Fissato",
	'yes': "Sì",
	'no': "No",
	'commemorate': "Commemora",
	'action_received_by_page': "Grazie per il tuo omaggio!",
	'write_a_message_to_page': "Scrivi un messaggio",
	'send_a_photo_to_page': "Invia una foto",
	'send_a_video_to_page': "Invia un video",
	'return_to_page': "Ritorna alla pagina",

	'free_flowers_available_message': "Hai a disposizione {{value}} elementi dello store gratuiti, approfittane subito!"
}