import { RouteObject } from "react-router-dom";
import AuthenticatedRoute from "../Components/AuthenticatedRoute";
import ConfigsPage from "../Pages/ConfigsPage";
import HomeDashboard from "../Pages/HomeDashboard";
import PendingManagers from "../Pages/PendingManagers";
import PeopleList from "../Pages/PeopleList";
import StoreProductsSectionsPage from "../Pages/StoreProductsSectionsPage";
import UsersList from "../Pages/UsersList";
import WallpapersPage from "../Pages/WallpapersPage";
import AbuseReportsList from "../Pages/AbuseReportsList";
import AbuseReportsDetail from "../Pages/AbuseReportDetail";
import PublicPages from "../Pages/PublicPages";
import SocialDeleteWills from "../Pages/SocialDeleteWills";

export const adminNavigation: RouteObject[] = [
	{
		path: "/dashboard",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<HomeDashboard />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/users",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<UsersList />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/people",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<PeopleList />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/pending-manager-requests",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<PendingManagers />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/social-delete-wills",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<SocialDeleteWills />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/abuse-reports",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<AbuseReportsList />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/abuse-reports/:id",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<AbuseReportsDetail />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/products",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<StoreProductsSectionsPage />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/wallpapers",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<WallpapersPage />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/public_pages",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<PublicPages/>
			</AuthenticatedRoute>
		)
	},
	{
		path: "/config",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<ConfigsPage />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/manager/:id/dashboard",
		element: (
			<AuthenticatedRoute requiredRole="admin">
				<HomeDashboard />
			</AuthenticatedRoute>
		)
	}
]