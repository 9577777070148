import { CSSProperties } from "react";
import { colors } from "./colors";

export const container: CSSProperties = {
	display: 'flex', width: "100vw", justifyContent: 'center', backgroundColor: colors.veryLightGrey, flex: 1, overflow: 'auto'
}

export const cardTitle: CSSProperties = {
	fontSize: 22,
	fontWeight: 'bold'
}

export const modalTitle: CSSProperties = {
	fontSize: 20,
	fontWeight: 'bold',
	marginBottom: 20
}