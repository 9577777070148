import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Sidebar from '../../Components/Sidebar'
import { managerNavigation } from '../../RoutesConfig/manager.navigation'
import sidebarItems from './sidebarItems'

const ManagerRouter = () => {
	return (
		<div style={{ display: 'flex', height: "100vh", width: "100vw" }}>
			<Sidebar items={sidebarItems} />
			<Routes>
				{managerNavigation.map((item, index) => (
					<Route
						key={index}
						path={item.path}
						element={item.element}
					/>
				))}
			</Routes>
		</div>
	)
}

export default ManagerRouter
