import { RouteObject } from "react-router-dom";
import AuthenticatedRoute from "../Components/AuthenticatedRoute";
import HomeDashboard from "../Pages/HomeDashboard";
import PeopleList from "../Pages/PeopleList";

export const managerNavigation: RouteObject[] = [
	{
		path: "/dashboard",
		element: (
			<AuthenticatedRoute requiredRole="manager">
				<HomeDashboard />
			</AuthenticatedRoute>
		)
	},
	{
		path: "/people",
		element: (
			<AuthenticatedRoute requiredRole="manager">
				<PeopleList />
			</AuthenticatedRoute>
		)
	}
]