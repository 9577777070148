import React, { useEffect, useCallback } from 'react';
import './App.css';
import 'react-nestable/dist/styles/index.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { rootNavigation } from './RoutesConfig/root.navigation';
import { initTranslations } from './petal-client-common/Translation';
import getUserLocale from 'get-user-locale'
import { webPath } from './Global/util';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

function App() {

	initTranslations(getUserLocale())

	return (
		<div className="App">
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Router>
					<Routes>
						{
							rootNavigation.map((route, index) => (
								<Route
									key={index}
									element={route.element}
									path={webPath(route.path)}
								/>
							))
						}
					</Routes>
				</Router>
			</LocalizationProvider>
		</div>
	);
}

export default App;
