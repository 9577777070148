import { Action } from "./Action";
import { MyLaterItem } from "./MyLaterItem";
import { Gender, User } from "./User";
import { Wallpaper } from "./Wallpaper";

export type Person = {
	id: number
	profile_image: string
	profile_image_thumb: string
	first_name: string
	last_name: string
	keywords?: string[]
	gender: Gender
	birth_day: number
	birth_month: number
	birth_year: number
	death_day: number
	death_month: number
	death_year: number
	cemetery: string | null
	tomb_type: TombType,
	tomb_photo_1: string | null
	tomb_photo_2: string | null
	creator_id: number
	creator?: User
	is_later?: boolean
	is_later_enabled?: boolean
	isRelative?: boolean
	isFavourite?: boolean
	purchased?: Action[]
	relative?: User,
	/* action_message?: Action
	action_photo?: Action
	action_video?: Action */
	preview_actions: Action[]
	purchasable_actions: Action[]
	my_later_items?: MyLaterItem[]
	created_at: string
	admin_id?: number
	admin?: User
	admin_email?: string | null
	active_wallpaper?: Wallpaper | null
	is_fake: boolean
}

export type TombType = 
	'niche_in_gallery' | 
	'niche_in_family_tomb' | 
	'niche_cinerary_urn' | 
	'ground_tomb' | 
	'common_ossary' | 
	'urn_in_family' |
	'spread_common_cinerary' |
	'spread_garden' |
	'spread_nature'

export type TombDesc = {
	type: TombType
	photo1Required: boolean,
	photo1Show: boolean,
	photo2Required: boolean,
	photo2Show: boolean,
	requiresCemetery: boolean
}

export const tombs: TombDesc[] = [
	{
		type: 'niche_in_gallery',
		photo1Required: true,
		photo1Show: true,
		photo2Required: false,
		photo2Show: true,
		requiresCemetery: true
	},
	{
		type: 'niche_in_family_tomb',
		photo1Required: true,
		photo1Show: true,
		photo2Required: false,
		photo2Show: true,
		requiresCemetery: true
	},
	{
		type: 'niche_cinerary_urn',
		photo1Required: true,
		photo1Show: true,
		photo2Required: false,
		photo2Show: true,
		requiresCemetery: true
	},
	{
		type: 'ground_tomb',
		photo1Required: true,
		photo1Show: true,
		photo2Required: false,
		photo2Show: true,
		requiresCemetery: true
	},
	{
		type: 'common_ossary',
		photo1Required: true,
		photo1Show: true,
		photo2Required: false,
		photo2Show: true,
		requiresCemetery: true
	},
	{
		type: 'spread_common_cinerary',
		photo1Required: true,
		photo1Show: true,
		photo2Required: false,
		photo2Show: true,
		requiresCemetery: false
	},
	{
		type: 'spread_garden',
		photo1Required: true,
		photo1Show: true,
		photo2Required: false,
		photo2Show: true,
		requiresCemetery: false
	},
	{
		type: 'spread_nature',
		photo1Required: true,
		photo1Show: true,
		photo2Required: false,
		photo2Show: true,
		requiresCemetery: false
	},
	{
		type: 'urn_in_family',
		photo1Required: true,
		photo1Show: true,
		photo2Required: false,
		photo2Show: false,
		requiresCemetery: false
	},
]

export const tombTypeString = (type: TombType, translate: (key: any) => string) => {
	return translate(type)
}

export const tombByPerson = (person: Person): TombInfo => {
	return {
		tomb_type: person.tomb_type,
		selected: true,
		photo_1: person.tomb_photo_1,
		photo_2: person.tomb_photo_2
	}
}

export type TombInfo = {
	tomb_type: TombType
	selected: boolean
	photo_1?: string | null
	photo_2?: string | null
}

export const personDisplayName = (person?: Person) => {
	if(!person) return ""
	return person.first_name + " " + person.last_name
}

export const groupBirth = (person: Person) => {
	return {
		d: person.birth_day,
		m: person.birth_month,
		y: person.birth_year
	}
}

export const groupDeath = (person: Person) => {
	return {
		d: person.death_day,
		m: person.death_month,
		y: person.death_year
	}
}