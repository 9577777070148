export type UserRole = 'user' | 'manager' | 'admin'
export type Gender = 'M' | 'F' | 'A'

export type User = {
	profile_image: string | null
	profile_image_thumb: string | null
	id: number
	auth_id: string
	role: UserRole
	approved_role: boolean
	rejected_role: boolean
	revenue_percentage: number
	blocked: boolean
	email: string
	first_name: string | null
	last_name: string | null
	gender: Gender | null
	birth_day: number
	birth_month: number
	birth_year: number
	address: string | null
	house_number: string | null
	city: string | null
	post_code: string | null
	country: Country | null
	lang: string
	created_at: string
	tos_accepted: string
	privacy_accepted: string
	tax_id: string | null
	iban: string | null
	dead: boolean
	free_flowers_available: number
}

export type Country = {
	code: string
	name?: string
}

export type ActivatorInvite = {
	id?: number
	status?: ActivatorInviteStatus
	role?: ActivatorRole
	email?: string | null
	user_id?: number
	user?: User
	activator_id?: number | null
	activator?: User | null
	created_at?: string
	dead_process?: DeadProcess
	can_start_dead_process?: boolean
}

export type ActivatorRole = "parent" | "son" | "sibiling" | "relative" | "friend"
export const activatorRolesCollection: ActivatorRole[] = ["parent", "son", "sibiling", "relative", "friend"]
export type ActivatorInviteStatus = "pending" | "approved" | "rejected"

export const isUserComplete = (user: User | undefined) => {
	if (!user) return false
	if (isManager(user)) {
		return user.first_name &&
			user.address &&
			user.house_number &&
			user.city &&
			user.post_code &&
			user.country
	} else {
		return user.first_name &&
			user.last_name &&
			user.birth_day &&
			user.birth_month &&
			user.birth_year
	}
}

export const isManager = (user: User) => {
	return user.role == 'manager'
}

export const userDisplayName = (user?: User) => {
	if(!user) return ""
	if(isManager(user)) {
		return user.first_name
	} else {
		return user.first_name + " " + user.last_name
	}
}

export const isRoleApproved = (user: User) => {
	if(isManager(user)) {
		return user.approved_role
	}

	return true
}

export type DeadProcess = {
	id: number
	created_at: string
	confirmed: boolean
	initiator_id: number
	initiator?: User
	user_id: number
	user?: User
	confirms?: DeadProcessConfirm[]
}

export type DeadProcessConfirm = {
	id: number
	document: string
	created_at: string
	process_id: number
	process?: DeadProcess
	user_id: number
	user?: User
}