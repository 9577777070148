/* export default {
	env: 'dev',
	web_app: "http://localhost:8888",
	api_url: 'http://localhost:8888/api/'
} */

export default {
	env: 'prod',
	web_app: "https://app.petalsocial.com",
	api_url: 'https://app.petalsocial.com/api/'
}