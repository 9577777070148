import React, { CSSProperties, useEffect, useState } from 'react'
import { Action } from '../../petal-common/Models/Action'
import { colors } from '../../Global/colors'
import { User, userDisplayName } from '../../petal-common/Models/User'
import { humanDate } from '../../Global/util'
import { Person, personDisplayName } from '../../petal-common/Models/Person'

type Props = {
	user?: User
	person?: Person
	date?: string
	reverse?: boolean
	style?: CSSProperties
}

const UserCell = (props: Props) => {

	useEffect(() => {

	}, [])


	return (
		<div style={{ display: 'flex', flexDirection: props.reverse ? "row-reverse" : undefined, alignItems: 'center', ...props.style }}>
			<img style={{ height: 80, width: 80, borderRadius: 40 }} src={(props.user?.profile_image_thumb || props.person?.profile_image_thumb) ?? undefined}/>
			<div style={{ ...(props.reverse ? { marginRight: 20 } : { marginLeft: 20 }), display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
				{props.user && <div style={{ fontWeight: 'bold' }}>{userDisplayName(props.user)}</div>}
				{props.person && <div style={{ fontWeight: 'bold' }}>{personDisplayName(props.person)}</div>}
				{props.date && <div style={{ marginTop: 10 }}>{humanDate(props.date)}</div>}
			</div>
		</div>
	)
}

export default UserCell
