import React, { useEffect, useState } from 'react'
import CButton from '../../Components/CButton'
import { container } from '../../Global/styles'
import * as MDIcons from "react-icons/md"
import { Wallpaper } from '../../petal-common/Models/Wallpaper'
import DraggableList from '../../Components/DraggableList'
import WallpaperCell from '../../Components/ListCells/WallpaperCell'
import CModal from '../../Components/CModal'
import CTextField from '../../Components/CTextField'
import PhotoPicker from '../../Components/PhotoPicker'
import { Checkbox } from '@mui/material'
import { genericError, successToast } from '../../Global/toast'
import { createWallpaper, deleteWallpaper, getWallpapers, reorderWallpapers, updateWallpaper, uploadWallpaperPhoto } from '../../Network/wallpapers'
import { Toaster } from 'react-hot-toast'
import PageLoading from '../../Components/PageLoading'

const WallpapersPage = () => {

	useEffect(() => {
		loadData()
	}, [])

	const [data, setData] = useState<Wallpaper[]>([])
	const [loading, setLoading] = useState(true)
	const [saveLoading, setSaveLoading] = useState(false)
	const [addWallpaperOpen, setAddWallpaperOpen] = useState(false)
	const [editWallpaperOpen, setEditWallpaperOpen] = useState(false)
	const [deleteWallpaperOpen, setDeleteWallpaperOpen] = useState(false)

	const [newStoreId, setNewStoreId] = useState("")
	const [isNewFree, setIsNewFree] = useState(false)
	const [selectedWallpaper, setSelectedWallpaper] = useState<Wallpaper>()

	const [editedPhoto, setEditedPhoto] = useState<File>()
	const [displayPhoto, setDisplayPhoto] = useState<string>()

	const loadData = async () => {
		setLoading(true)
		try {
			const response = await getWallpapers()
			if(response.success) {
				setData(response.data?.wallpapers || [])
			} else {
				genericError()
			}
		} catch(e) {
			genericError()
		}
		setLoading(false)
	}

	const onAddWallpaperClick = () => {
		setNewStoreId("")
		setEditedPhoto(undefined)
		setDisplayPhoto(undefined)
		setAddWallpaperOpen(true)
	}

	const onEditWallpaperClick = (wallpaper: Wallpaper) => {
		setSelectedWallpaper(wallpaper)
		setEditedPhoto(undefined)
		setDisplayPhoto(undefined)
		setEditWallpaperOpen(true)
	}

	const onDeleteWallpaperClick = (wallpaper: Wallpaper) => {
		setSelectedWallpaper(wallpaper)
		setDeleteWallpaperOpen(true)
	}

	const onSaveWallpapersSorting = async (newOrder: Wallpaper[]) => {
		try {
			const response = await reorderWallpapers(newOrder.map((c, index) => ({ wallpaper_id: c.id, order: index })))
			if (response.success) {
				setData(newOrder)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
	}

	const onSaveWallpaperClick = async () => {
		setSaveLoading(true)
		try {
			const response = await createWallpaper({
				product_store_id: newStoreId,
				is_free: isNewFree
			})

			if (!response.success) {
				genericError()
				return
			}

			const wallpaper = response.data?.wallpaper

			if (wallpaper && editedPhoto) {
				await uploadWallpaperPhoto(wallpaper.id, editedPhoto)
			}

			setAddWallpaperOpen(false)
			setNewStoreId("")
			loadData()
			successToast("Sfondo creato con successo")
		} catch(e) {
			genericError()
		}
		setSaveLoading(false)
	}

	const onSaveEditWallpaperClick = async () => {
		if(!selectedWallpaper) return
		setSaveLoading(true)
		try {
			const response = await updateWallpaper(selectedWallpaper.id, {
				product_store_id: selectedWallpaper.product_store_id,
				is_free: selectedWallpaper.is_free
			})

			if (!response.success) {
				genericError()
				return
			}

			if (editedPhoto) {
				await uploadWallpaperPhoto(selectedWallpaper.id, editedPhoto)
			}

			setEditWallpaperOpen(false)
			setSelectedWallpaper(undefined)
			loadData()
			successToast("Sfondo aggiornato con successo")
		} catch(e) {
			genericError()
		}
		setSaveLoading(false)
	}

	const onDeleteWallpaperConfirmed = async () => {
		if(!selectedWallpaper) return
		setSaveLoading(true)

		try {
			const response = await deleteWallpaper(selectedWallpaper.id)
			if(response.success) {
				setDeleteWallpaperOpen(false)
				successToast("Sfondo eliminato con successo")
				setSelectedWallpaper(undefined)
				loadData()
			} else {
				genericError()
			}
		} catch(e) {
			genericError()
		}

		setSaveLoading(false)
	}

	if(loading) {
		return <PageLoading/>
	}

	return (
		<div style={{ ...container, paddingTop: 10, flexDirection: 'column', justifyContent: 'flex-start' }}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginLeft: 10 }}>
				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: 20 }}>
						<CButton startIcon={<MDIcons.MdAdd />} label='Aggiungi sfondo' onClick={onAddWallpaperClick} />
					</div>
				</div>
			</div>
			<div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', margin: 10 }}>
				{data.length > 0 && <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignSelf: 'stretch' }}>
					<DraggableList<Wallpaper>
						data={data}
						renderItem={(item) => (
							<WallpaperCell
								key={item.id}
								wallpaper={item}
								onDeleteClick={onDeleteWallpaperClick}
								onEditClick={onEditWallpaperClick}
							/>
						)}
						onChange={onSaveWallpapersSorting}
						map={(item) => ({ id: `${item.id}`, text: item.product_store_id })}
					/>
				</div>}
				{(data || []).length == 0 && <div style={{ alignSelf: 'center', margin: "50px 0px" }}>Non hai ancora aggiunto nessuno sfondo</div>}
			</div>

			<CModal
				title='Aggiungi sfondo'
				open={addWallpaperOpen}
				onClose={() => setAddWallpaperOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setAddWallpaperOpen(false) },
					{
						label: "Salva", onPress: onSaveWallpaperClick, loading: saveLoading, disabled: !newStoreId || !editedPhoto
					}
				]}
			>
				<div>
					<CTextField style={{ width: "100%" }} label="ID prodotto (quello inserito negli store)" value={newStoreId} onChange={e => setNewStoreId(e.target.value)} />
					<div>
						<PhotoPicker
							hideRemove
							displayMedia={displayPhoto}
							onMediaSelected={(photo, display) => {
								setEditedPhoto(photo)
								setDisplayPhoto(display)
							}}
						/>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
						<Checkbox checked={isNewFree} onChange={(_, checked) => setIsNewFree(checked)} />
						<div style={{  }}>Prodotto gratis</div>
					</div>
				</div>
			</CModal>

			<CModal
				title='Modifica sfondo'
				open={editWallpaperOpen}
				onClose={() => setEditWallpaperOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setEditWallpaperOpen(false) },
					{
						label: "Salva", onPress: onSaveEditWallpaperClick, loading: saveLoading, disabled: !selectedWallpaper?.product_store_id || (!editedPhoto && !selectedWallpaper.photo)
					}
				]}
			>
				<div>
					<CTextField style={{ width: "100%" }} label="ID prodotto (quello inserito negli store)" value={selectedWallpaper?.product_store_id} onChange={e => setSelectedWallpaper({ ...selectedWallpaper!, product_store_id: e.target.value })} />
					<div>
						<PhotoPicker
							hideRemove
							displayMedia={displayPhoto || selectedWallpaper?.photo}
							onMediaSelected={(photo, display) => {
								setEditedPhoto(photo)
								setDisplayPhoto(display)
							}}
						/>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
						<Checkbox checked={selectedWallpaper?.is_free} onChange={(_, checked) => setSelectedWallpaper({ ...selectedWallpaper!, is_free: checked })} />
						<div style={{  }}>Prodotto gratis</div>
					</div>
				</div>
			</CModal>

			<CModal
				title='Elimina sfondo'
				open={deleteWallpaperOpen}
				onClose={() => setDeleteWallpaperOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setDeleteWallpaperOpen(false) },
					{ label: "Elimina", destructive: true, onPress: onDeleteWallpaperConfirmed, loading: saveLoading }
				]}
			>
				<span>Sei sicuro di voler eliminare lo sfondo <span style={{ fontWeight: 'bold' }}>{selectedWallpaper?.product_store_id}</span>? Questa operazione non può essere annullata.</span>
			</CModal>

			<Toaster />
		</div>
	)
}

export default WallpapersPage
