import { http, loadUrl } from "."
import { loadJWT } from "../Services/local.storage"
import { Counters, DailyAnalytics } from "../petal-common/Models/Analytics"
import { User } from "../petal-common/Models/User"
import config from "./config"

type GetUsersResponse = {
	users: User[]
	count: number
	rows: number
}

type GetUsersParams = {
	limit?: number
	offset?: number
	pending_managers?: boolean
	query?: string
	reverse_order?: boolean
}
export const getUsers = (params?: GetUsersParams) => {
	const url = loadUrl("users", params)
	return http<GetUsersResponse>(url)
}

type UserResponse = {
	user: User
}
export const blockUser = (user_id: number, blockedStatus: boolean) => {
	return http<UserResponse>(`users/${user_id}/block`, 'PUT', {
		blocked_status: blockedStatus
	})
}

export const approveManager = (user_id: number, revenue_percentage: number) => {
	return http<UserResponse>(`users/${user_id}/approve`, "PUT", {
		revenue_percentage,
		approved: true
	})
}

export const rejectManager = (user_id: number) => {
	return http<UserResponse>(`users/${user_id}/approve`, "PUT", {
		approved: false
	})
}

export type CountersResponse = {
	counters: DailyAnalytics[],
	month: number,
	year: number
}
export const getCounters = (month: number, year: number) => {
	return http<CountersResponse>(loadUrl("counters", {
		month,
		year
	}))
}

export const getCountersForManager = (month: number, year: number, manager_id: number) => {
	return http<CountersResponse>(loadUrl(`users/${manager_id}/counters`, {
		month,
		year
	}))
}

export const downloadAllEmails = async () => {
	return http<{ emails: string[] }>("users/download-all-emails")
}