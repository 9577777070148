import React, { useEffect, useState } from 'react'
import CButton from '../../Components/CButton'
import SegmentedControl from '../../Components/SegmentedControl'
import { container } from '../../Global/styles'
import { genericError, successToast } from '../../Global/toast'
import { createProduct, createProductCategory, deleteProduct, deleteProductCategory, getProductCategories, reorderProductCategories, updateProduct, updateProductCategory, uploadProductPhoto } from '../../Network/products'
import { Product, ProductCategory, ProductSection } from '../../petal-common/Models/Product'
import sections from './sections'
import * as MDIcons from "react-icons/md";
import CModal from '../../Components/CModal'
import { ItemsMap, LocalizedString } from '../../petal-common/Models/Generic'
import { Checkbox, CircularProgress } from '@mui/material'
import MultiLangInput from '../../Components/MultiLangInput'
import ProductCategoryCell from '../../Components/ListCells/ProductCategoryCell'
import { isLocalizedStringFullFilled, localizedStringValue } from '../../Global/util'
import CTextField from '../../Components/CTextField'
import { Toaster } from 'react-hot-toast'
import PhotoPicker from '../../Components/PhotoPicker'
import DraggableList from '../../Components/DraggableList'

const StoreSectionsPage = () => {

	const [selectedSection, setSelectedSection] = useState<ProductSection>("flowers")
	const [loading, setLoading] = useState(true)
	const [saveLoading, setSaveLoading] = useState(false)
	const [data, setData] = useState<ProductCategory[]>([])
	const [newCategoryLabel, setNewCategoryLabel] = useState<ItemsMap<LocalizedString>>({})

	const [addCategoryOpen, setAddCategoryOpen] = useState(false)
	const [editCategoryOpen, setEditCategoryOpen] = useState(false)
	const [deleteCategoryOpen, setDeleteCategoryOpen] = useState(false)
	const [selectedCategory, setSelectedCategory] = useState<ProductCategory>()
	const [addProductOpen, setAddProductOpen] = useState(false)
	const [editProductOpen, setEditProductOpen] = useState(false)
	const [deleteProductOpen, setDeleteProductOpen] = useState(false)

	//New product data
	const [newStoreProductId, setNewProductId] = useState("")
	const [newProductName, setNewProductName] = useState<LocalizedString>({})
	const [newProductDescription, setNewProductDescription] = useState<LocalizedString>({})
	const [isNewProductFree, setIsNewProductFree] = useState(false)
	const [selectedProduct, setSelectedProduct] = useState<Product>()

	const [editedProductPhoto, setEditedProductPhoto] = useState<File>()
	const [displayProductPhoto, setDisplayProductPhoto] = useState<string>()

	useEffect(() => {
		loadData(selectedSection)
	}, [])

	const loadData = async (section: ProductSection) => {
		try {
			const response = await getProductCategories(section)
			if (response.success) {
				setData(response.data?.product_categories || [])
			}
		} catch (e) {
			genericError()
		}

		setLoading(false)
	}

	const onSectionChange = (val: string) => {
		setSelectedSection(val as ProductSection)
		loadData(val as ProductSection)
	}

	const onAddCategoryClick = () => {
		setAddCategoryOpen(true)
		setNewCategoryLabel({})
	}

	const onEditCategoryClick = (category: ProductCategory) => {
		setSelectedCategory(category)
		setEditCategoryOpen(true)
	}

	const onDeleteCategoryClick = (category: ProductCategory) => {
		setSelectedCategory(category)
		setDeleteCategoryOpen(true)
	}

	const onEditProductClick = (product: Product) => {
		setSelectedProduct(product)
		setEditProductOpen(true)
		setEditedProductPhoto(undefined)
		setDisplayProductPhoto(undefined)
	}

	const onDeleteProductClick = (product: Product) => {
		setSelectedProduct(product)
		setDeleteProductOpen(true)
	}

	const onSaveCategoryPress = async () => {
		setSaveLoading(true)
		try {
			const response = await createProductCategory({
				section: selectedSection,
				label: newCategoryLabel[selectedSection]
			})
			if (response.success) {
				setAddCategoryOpen(false)
				setNewCategoryLabel({})
				loadData(selectedSection)
				successToast("Categoria creata con successo")
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setAddCategoryOpen(false)
		setSaveLoading(false)
	}

	const onSaveEditCategoryPress = async () => {
		if (!selectedCategory) return
		setSaveLoading(true)
		try {
			const response = await updateProductCategory(selectedCategory.id, {
				label: selectedCategory.label
			})
			if (response.success) {
				setEditCategoryOpen(false)
				setSelectedCategory(undefined)
				loadData(selectedSection)
				successToast("Categoria aggiornata con successo")
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setAddCategoryOpen(false)
		setSaveLoading(false)
	}

	const onDeleteCategoryConfirmed = async () => {
		if (!selectedCategory) return
		setSaveLoading(true)
		try {
			const response = await deleteProductCategory(selectedCategory.id)
			if (response.success) {
				setDeleteCategoryOpen(false)
				setSelectedCategory(undefined)
				loadData(selectedSection)
				successToast("Categoria eliminata con successo")
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setAddCategoryOpen(false)
		setSaveLoading(false)
	}

	const onAddProductClick = async (category: ProductCategory) => {
		setSelectedCategory(category)
		setAddProductOpen(true)
		setSelectedProduct(undefined)
		setNewProductId("")
		setNewProductName({})
		setNewProductDescription({})
		setIsNewProductFree(false)
		setEditedProductPhoto(undefined)
		setDisplayProductPhoto(undefined)
	}

	const onSaveProductClick = async () => {
		if (!selectedCategory) return
		setSaveLoading(true)
		try {
			const response = await createProduct(selectedCategory.id, {
				store_product_id: newStoreProductId,
				name: newProductName,
				description: newProductDescription,
				is_free: isNewProductFree
			})
			if (!response.success) {
				genericError()
				return
			}

			const product = response.data?.product

			if (product && editedProductPhoto) {
				await updateProductPhoto(product.id, editedProductPhoto)
			}

			setAddProductOpen(false)
			setSelectedCategory(undefined)
			loadData(selectedSection)
			successToast("Prodotto creato con successo")
		} catch (e) {
			genericError()
		}
		setSaveLoading(false)
	}

	const onSaveEditProductClick = async () => {
		if (!selectedProduct) return
		setSaveLoading(true)
		try {
			const response = await updateProduct(selectedProduct.id, {
				store_product_id: selectedProduct.store_product_id,
				name: selectedProduct.name,
				description: selectedProduct.description,
				is_free: selectedProduct.is_free
			})
			if (!response.success) {
				genericError()
				return
			}

			if (editedProductPhoto) {
				await updateProductPhoto(selectedProduct.id, editedProductPhoto)
			}

			successToast("Prodotto aggiornato con successo")
			setSelectedProduct(undefined)
			setEditProductOpen(false)
			loadData(selectedSection)
		} catch (e) {
			genericError()
		}
		setSaveLoading(false)
	}

	const updateProductPhoto = async (product_id: number, file: File) => {
		const response = await uploadProductPhoto(product_id, file)
		if (!response.success) {
			throw new Error("Error uploading photo")
		}
	}

	const onDeleteProductConfirmed = async () => {
		if (!selectedProduct) return
		setSaveLoading(true)
		try {
			const response = await deleteProduct(selectedProduct.id)
			if (response.success) {
				successToast("Prodotto eliminato con successo")
				setSelectedProduct(undefined)
				setDeleteProductOpen(false)
				loadData(selectedSection)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setSaveLoading(false)
	}

	const onSaveCategoriesSorting = async (newOrder: ProductCategory[]) => {
		try {
			const response = await reorderProductCategories(selectedSection, newOrder.map((c, index) => ({ category_id: c.id, order: index })))
			if (response.success) {
				setData(newOrder)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
	}

	if (loading) {
		return (
			<div style={{ ...container, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
				<CircularProgress size={36} />
			</div>
		)
	}

	return (
		<div style={{ ...container, paddingTop: 10, flexDirection: 'column', justifyContent: 'flex-start' }}>
			<div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
				<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
					<SegmentedControl
						items={sections}
						value={selectedSection}
						onChange={onSectionChange}
					/>
				</div>
				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: 20 }}>
						<CButton startIcon={<MDIcons.MdAdd />} label='Aggiungi categoria' onClick={onAddCategoryClick} />
					</div>
				</div>
			</div>
			<div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
				{data.length > 0 && <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignSelf: 'stretch' }}>
					<DraggableList<ProductCategory>
						data={data}
						renderItem={(item) => (
							<ProductCategoryCell
								key={item.id}
								category={item}
								onEditPress={onEditCategoryClick}
								onDeletePress={onDeleteCategoryClick}
								onAddProductPress={onAddProductClick}
								onEditProductClick={onEditProductClick}
								onDeleteProductClick={onDeleteProductClick}
							/>
						)}
						onChange={onSaveCategoriesSorting}
						map={(item) => ({ id: `${item.id}`, text: localizedStringValue(item.label) })}
					/>
				</div>}
				{(data || []).length == 0 && <div style={{ alignSelf: 'center', margin: "50px 0px" }}>Non hai ancora aggiunto nessuna categoria di prodotti per questa sezione</div>}
			</div>

			<CModal
				title='Aggiungi categoria'
				open={addCategoryOpen}
				onClose={() => setAddCategoryOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setAddCategoryOpen(false) },
					{ label: "Salva", onPress: onSaveCategoryPress, loading: saveLoading, disabled: !isLocalizedStringFullFilled(newCategoryLabel[selectedSection] || {}) }
				]}
			>
				<MultiLangInput
					value={newCategoryLabel[selectedSection]}
					onChangeValue={(val) => setNewCategoryLabel({
						...newCategoryLabel,
						[selectedSection]: val
					})}
				/>
			</CModal>

			<CModal
				title='Modifica categoria'
				open={editCategoryOpen}
				onClose={() => setEditCategoryOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setEditCategoryOpen(false) },
					{ label: "Salva", onPress: onSaveEditCategoryPress, loading: saveLoading, disabled: !isLocalizedStringFullFilled(selectedCategory?.label || {}) }
				]}
			>
				<MultiLangInput
					value={selectedCategory?.label}
					onChangeValue={(val) => setSelectedCategory({
						...selectedCategory!,
						label: val
					})}
				/>
			</CModal>

			<CModal
				title='Elimina categoria'
				open={deleteCategoryOpen}
				onClose={() => setDeleteCategoryOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setDeleteCategoryOpen(false) },
					{ label: "Elimina", destructive: true, onPress: onDeleteCategoryConfirmed, loading: saveLoading }
				]}
			>
				<span>Sei sicuro di voler eliminare la categoria <span style={{ fontWeight: 'bold' }}>{localizedStringValue(selectedCategory?.label || {})}</span>? Tutti i prodotti di questa categoria verranno eliminati. Questa operazione non può essere annullata.</span>
			</CModal>

			<CModal
				title='Aggiungi prodotto'
				open={addProductOpen}
				onClose={() => setAddProductOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setAddProductOpen(false) },
					{
						label: "Salva", onPress: onSaveProductClick, loading: saveLoading, disabled: (
							!newStoreProductId ||
							!isLocalizedStringFullFilled(newProductName) ||
							!isLocalizedStringFullFilled(newProductDescription) ||
							!editedProductPhoto
						)
					}
				]}
			>
				<div>
					<CTextField style={{ width: "100%" }} label="ID prodotto (quello inserito negli store)" value={newStoreProductId} onChange={e => setNewProductId(e.target.value)} />
					<div>
						<PhotoPicker
							hideRemove
							displayMedia={displayProductPhoto || selectedProduct?.photo}
							onMediaSelected={(photo, display) => {
								setEditedProductPhoto(photo)
								setDisplayProductPhoto(display)
							}}
						/>
					</div>
					<MultiLangInput title='Nome prodotto' onChangeValue={val => setNewProductName(val)} value={newProductName} />
					<MultiLangInput title='Descrizione prodotto' multiline onChangeValue={val => setNewProductDescription(val)} value={newProductDescription} />
					<div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
						<Checkbox checked={isNewProductFree} onChange={(_, checked) => setIsNewProductFree(checked)} />
						<div style={{  }}>Prodotto gratis</div>
					</div>
				</div>
			</CModal>

			<CModal
				title='Modifica prodotto'
				open={editProductOpen}
				onClose={() => setEditProductOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setEditProductOpen(false) },
					{
						label: "Salva", onPress: onSaveEditProductClick, loading: saveLoading, disabled: (
							!selectedProduct?.store_product_id ||
							!isLocalizedStringFullFilled(selectedProduct.name) ||
							!isLocalizedStringFullFilled(selectedProduct.description) ||
							(!editedProductPhoto && !selectedProduct.photo)
						)
					}
				]}
			>
				<div>
					<CTextField style={{ width: "100%" }} label="ID prodotto (quello inserito negli store)" value={selectedProduct?.store_product_id} onChange={e => setSelectedProduct({ ...selectedProduct!, store_product_id: e.target.value })} />
					<div>
						<PhotoPicker
							hideRemove
							displayMedia={displayProductPhoto || selectedProduct?.photo}
							onMediaSelected={(photo, display) => {
								setEditedProductPhoto(photo)
								setDisplayProductPhoto(display)
							}}
						/>
					</div>
					<MultiLangInput title='Nome prodotto' onChangeValue={val => setSelectedProduct({ ...selectedProduct!, name: val })} value={selectedProduct?.name} />
					<MultiLangInput title='Descrizione prodotto' multiline onChangeValue={val => setSelectedProduct({ ...selectedProduct!, description: val })} value={selectedProduct?.description} />
					<div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
						<Checkbox checked={selectedProduct?.is_free} onChange={(_, checked) => setSelectedProduct({ ...selectedProduct!, is_free: checked })} />
						<div style={{  }}>Prodotto gratis</div>
					</div>
				</div>
			</CModal>

			<CModal
				title='Elimina prodotto'
				open={deleteProductOpen}
				onClose={() => setDeleteProductOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setDeleteProductOpen(false) },
					{ label: "Elimina", destructive: true, onPress: onDeleteProductConfirmed, loading: saveLoading }
				]}
			>
				<span>Sei sicuro di voler eliminare il prodotto <span style={{ fontWeight: 'bold' }}>{localizedStringValue(selectedProduct?.name || {})}</span>? Questa operazione non può essere annullata.</span>
			</CModal>

			<Toaster />
		</div>
	)
}

export default StoreSectionsPage
