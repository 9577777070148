import { SegmentedItem } from "../../Components/SegmentedControl";

export default [
	{
		label: "Fiori",
		value: "flowers"
	},
	{
		label: "Led",
		value: "leds"
	},
	{
		label: "Regali",
		value: "gifts"
	}
] as SegmentedItem[]