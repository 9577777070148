import toast from "react-hot-toast"
import { translate } from "../petal-client-common/Translation"
import { colors } from "./colors"

export const successToast = (message: string) => {
	toast(message, {
		style: {
			backgroundColor: colors.success,
		}
	})
}

export const errorToast = (message: string) => {
	toast(message, {
		style: {
			backgroundColor: colors.red,
			color: colors.white
		}
	})
}

export const genericError = () => {
	errorToast(translate("generic_error"))
}