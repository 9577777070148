import React, { useState, useEffect, useCallback } from 'react'
import { container } from '../../Global/styles'
import CTable from '../../Components/CTable'
import { PublicPage } from '../../petal-common/Models/PublicPage'
import PageLoading from '../../Components/PageLoading'
import { translate } from '../../petal-client-common/Translation'
import CButton from '../../Components/CButton'
import { Toaster } from 'react-hot-toast'
import { DEFAULT_PAGE_SIZE, humanDate, localizedStringValue } from '../../Global/util'
import { usePagination, useUser } from '../../Global/hooks'
import { createPublicPage, deletePublicPage, editPublicPage, getPublicPages, updatePublicPagePhoto } from '../../Network/public_pages'
import { genericError, successToast } from '../../Global/toast'
import * as MDIcons from "react-icons/md"
import CModal from '../../Components/CModal'
import MultiLangInput from '../../Components/MultiLangInput'
import { LocalizedString } from '../../petal-common/Models/Generic'
import PhotoPicker from '../../Components/PhotoPicker'
import { Checkbox } from '@mui/material'
import LargeSetSelect from '../../Components/LargeSetSelect'
import countries from '../../petal-common/utils/countries.json'
import { Country } from '../../petal-common/Models/Country'

const PublicPages = () => {

	const user = useUser()

	const [loading, setLoading] = useState(true)
	const [data, setData] = useState<PublicPage[]>([])

	const [editingPageId, setEditingPageId] = useState<number>()
	const [newPageOpen, setNewPageOpen] = useState(false)
	const [newPageName, setNewPageName] = useState<LocalizedString>()
	const [newPageDescription, setNewPageDescription] = useState<LocalizedString>()
	const [newPageSelectedCountries, setNewPageSelectedCountries] = useState<Country[]>([])
	const [editedPhoto, setEditedPhoto] = useState<File>()
	const [displayPhoto, setDisplayPhoto] = useState<string>()
	const [newPagePinned, setNewPagePinned] = useState(false)
	const [saveLoading, setSaveLoading] = useState(false)

	const [deletePublicPageOpen, setDeletePublicPageOpen] = useState(false)

	const pagination = usePagination()

	useEffect(() => {
		fetchData(DEFAULT_PAGE_SIZE, 0)
	}, [])

	const fetchData = async (limit: number, offset: number) => {
		setLoading(true)
		try {
			const response = await getPublicPages({
				limit,
				offset: offset * limit,
			})
			console.log(response)
			if (response.success) {
				setData(response.data?.public_pages || [])
				pagination.setCount(response.data?.count || 0)
				pagination.setRows(response.data?.rows || 0)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setLoading(false)
	}

	const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
		pagination.setPage(newPage)
		fetchData(DEFAULT_PAGE_SIZE, newPage)
	}

	const onAddPageClick = () => {
		setNewPageSelectedCountries([])
		setNewPageOpen(true)
	}

	const onSavePageClick = async () => {
		if(!user) return
		console.log(newPageName)
		setSaveLoading(true)

		let response
		let page
		
		if(editingPageId) {
			response = await editPublicPage(editingPageId, {
				name: newPageName,
				description: newPageDescription,
				pinned: newPagePinned,
				user_id: user.id,
				countries: newPageSelectedCountries.map(c => c.code).join(",")
			})
		} else {
			response = await createPublicPage({
				name: newPageName,
				description: newPageDescription,
				pinned: newPagePinned,
				user_id: user.id,
				countries: newPageSelectedCountries.map(c => c.code).join(",")
			})
		}

		if (!response?.success) {
			genericError()	
			return
		}

		page = response.data?.public_page

		if(page && editedPhoto) {
			const mediaResponse = await updatePublicPagePhoto(page.id, editedPhoto)
			if(!mediaResponse.success) {
				genericError()
				return
			}
		}

		fetchData(pagination.rows, pagination.page)
		successToast(`Pagina creata con successo`)

		closePublicPageModal()
		resetNewPageModal()
		setSaveLoading(false)
	}

	const resetNewPageModal = () => {
		setNewPageName(undefined)
		setNewPageDescription(undefined)
		setNewPagePinned(false)
		setEditedPhoto(undefined)
		setDisplayPhoto(undefined)
		setEditingPageId(undefined)
	}

	const fillNewPageModal = (page: PublicPage) => {
		setNewPageName(page.name)
		setNewPageDescription(page.description)
		setNewPagePinned(page.pinned)
		setEditedPhoto(undefined)
		setDisplayPhoto(page.photo)
		setEditingPageId(page.id)
		setNewPageSelectedCountries(countries.filter(c => page.countries.includes(c.code)))
	}

	const onDeletePageConfirmed = async () => {
		if(!editingPageId) return
		try {
			const response = await deletePublicPage(editingPageId)
			if (response.success) {
				fetchData(pagination.rows, pagination.page)
				setDeletePublicPageOpen(false)
				setEditingPageId(undefined)
				successToast(`Pagina eliminata con successo`)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
	}

	const closePublicPageModal = useCallback(() => {
		setNewPageOpen(false)
		setEditingPageId(undefined)
	}, [])

	if(loading) {
		return <PageLoading/>
	}

	return (
		<div style={{ ...container, flexDirection: 'column', justifyContent: "flex-start" }}>
			<div style={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 10px 0px 10px" }}>
			<CButton startIcon={<MDIcons.MdAdd />} label='Aggiungi pagina' onClick={onAddPageClick} />
			</div>
			<CTable<PublicPage>
				data={data}
				headerLabels={[
					{ label: "" },
					{ label: translate('name') },
					{ label: translate('description') },
					{ label: translate('creation_date') },
					{ label: translate("pinned") },
					{ label: "" }
				]}
				rowCells={(page) => [
					{ content: <img style={{ height: 100, width: 100, objectFit: "contain" }} src={page.photo}/> },
					{ content: localizedStringValue(page.name) },
					{ content: localizedStringValue(page.description) },
					{ content: humanDate(page.created_at) },
					{ content: translate(page.pinned ? "yes" : "no") },
					{
						content: <div style={{ display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'flex-end' }}>
							<CButton style={{ width: 180 }} label={translate("delete")} onClick={() => {
								setDeletePublicPageOpen(true)
								setEditingPageId(page.id)
							}} />
							<CButton style={{ width: 180, marginTop: 10 }} label={translate("edit")} onClick={() => {
								fillNewPageModal(page)
								setNewPageOpen(true)
							}} />
						</div>
					}
				]}
				pagination={pagination}
				onPageChange={onPageChange}
			/>

			<CModal
				title={editingPageId ? "Modifica pagina" : 'Aggiungi pagina'}
				open={newPageOpen}
				onClose={() => closePublicPageModal()}
				actions={[
					{ label: "Chiudi", onPress: () => closePublicPageModal() },
					{
						label: "Salva", onPress: onSavePageClick, loading: saveLoading, disabled: !newPageName || !newPageDescription || !displayPhoto
					}
				]}
			>
				<div>
					<LargeSetSelect<Country>
						data={countries}
						data_key='code'
						modalTitle='Seleziona paesi'
						multi
						values={newPageSelectedCountries}
						mapping={(item) => ({ value: item.code, label: item.name })}
						widget={(onOpen) => (
							<CButton label='Seleziona paesi' onClick={onOpen}/>
						)}
						onMultipleItemsSelected={(selectedItems) => {
							setNewPageSelectedCountries(selectedItems)
						}}
					/>
					<MultiLangInput title='Nome della pagina' onChangeValue={setNewPageName} value={newPageName}/>
					<MultiLangInput multiline title='Descrizione della pagina' onChangeValue={setNewPageDescription} value={newPageDescription}/>

					<div>
						<PhotoPicker
							hideRemove
							displayMedia={displayPhoto}
							onMediaSelected={(photo, display) => {
								setEditedPhoto(photo)
								setDisplayPhoto(display)
							}}
						/>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
						<Checkbox checked={newPagePinned} onChange={(_, checked) => setNewPagePinned(checked)} />
						<div style={{  }}>Fissa in alto nel diario</div>
					</div>
				</div>
			</CModal>

			<CModal
				title='Elimina prodotto'
				open={deletePublicPageOpen}
				onClose={() => setDeletePublicPageOpen(false)}
				actions={[
					{ label: "Chiudi", onPress: () => setDeletePublicPageOpen(false) },
					{ label: "Elimina", destructive: true, onPress: onDeletePageConfirmed, loading: saveLoading }
				]}
			>
				<span>Sei sicuro di voler eliminare questa pagina? Questa operazione non può essere annullata.</span>
			</CModal>

			<Toaster />
		</div>
	)
}

export default PublicPages
