import React, { CSSProperties, useEffect, useState } from 'react'
import { Action } from '../../petal-common/Models/Action'
import { colors } from '../../Global/colors'
import UserCell from '../UserCell'

type Props = {
	action: Action
	style?: CSSProperties
}

const ActionView = ({ action }: { action: Action }) => {
	if (action.type == "message") {
		return <div>
			{action.message}
		</div>
	} else if (action.type == "image") {
		return <div style={{ display: 'flex', flexDirection: 'column' }}>
			<div>{action.caption}</div>
			<img style={{ marginTop: 20, maxHeight: 500, objectFit: "contain" }} src={action.photo} />
		</div>
	} else if (action.type == "video") {
		return <video controls height={600}>
			<source src={action.video} type="video/webm" />
			<source src={action.video} type="video/mp4"
			/>
		</video>
	} else if (["flowers", "leds", "gifts"].includes(action.type)) {
		return <div style={{ display: 'flex', flexDirection: 'column' }}>
			<div>{action.caption}</div>
			<img style={{ marginTop: 20, maxHeight: 300, objectFit: "contain" }} src={action.product?.photo} />
		</div>
	}
	
	return <></>
}

const ActionDetail = (props: Props) => {

	useEffect(() => {

	}, [])


	return (
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			minWidth: 600,
			borderRadius: 10,
			boxShadow: `5px 5px 5px ${colors.lightGrey}`,
			padding: 20,
			backgroundColor: colors.white,
			...props.style
		}}>
			{props.action.user && <UserCell date={props.action.created_at} user={props.action.user} />}
			<div style={{ flex: 1, margin: "30px 0px" }}>
				<ActionView action={props.action} />
			</div>
			<UserCell person={props.action.person} reverse />
		</div>
	)
}

export default ActionDetail
