import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { User } from "../petal-common/Models/User"
import { loadUser } from "../Services/local.storage"

export const useUser = () => {
	
	const [user, setUser] = useState<User | null>()

	useEffect(() => {
		setUser(loadUser())
	}, [])

	return user
}


type PaginationOptions = {
	count: number
	rows: number
	page: number
}

export type Pagination = {
	set: (paginationOptions: Partial<PaginationOptions>) => void
	count: number
	setCount: Dispatch<SetStateAction<number>>
	rows: number
	setRows: Dispatch<SetStateAction<number>>
	page: number
	setPage: Dispatch<SetStateAction<number>>
}

export const usePagination = (): Pagination => {
	const [count, setCount] = useState(0)
	const [rows, setRows] = useState(0)
	const [page, setPage] = useState(0)

	const set = useMemo(() => {
		return (paginationOptions: Partial<PaginationOptions>) => {
			if(paginationOptions.count) setCount(paginationOptions.count)
			if(paginationOptions.rows) setRows(paginationOptions.rows)
			if(paginationOptions.page) setRows(paginationOptions.page)
		}
	}, [])

	return {
		set,
		count, setCount,
		rows, setRows,
		page, setPage
	}
}