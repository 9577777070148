import React, { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ENTRY_POINT, webPath } from '../../Global/util'
import { UserRole } from '../../petal-common/Models/User'
import { loadUser } from '../../Services/local.storage'

type Props = {
	requiredRole: UserRole
	children: ReactNode
}

const AuthenticatedRoute = (props: Props) => {

	const navigate = useNavigate()

	useEffect(() => {
		const user = loadUser()
		if(!user) {
			navigate(ENTRY_POINT)
		} else if(user?.role == "manager" && props.requiredRole == "admin") {
			navigate(webPath("/manager/dashboard"))
		}
	}, [])

	return (
		<div style={{ width: "100vh", display: 'flex', flex: 1 }}>
			{props.children}			
		</div>
	)
}

export default AuthenticatedRoute
