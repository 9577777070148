import { http, loadUrl, multipart } from "."
import { StorePromoBanner } from "../petal-common/Models/StorePromoBanner"

type StorePromoBannersResponse = {
	store_promo_banners: StorePromoBanner[]
}
export const getStorePromoBanners = () => {
	return http<StorePromoBannersResponse>(loadUrl(`store-promo-banners`, {
		future: true
	}))
}

type StorePromoBannerResponse = {
	store_promo_banner: StorePromoBanner
}

export const createStorePromoBanner = (data: Partial<StorePromoBanner>) => {
	return http<StorePromoBannerResponse>("store-promo-banners", "POST", data)
}

export const updateStorePromoBanner = (banner_id: number, data: Partial<StorePromoBanner>) => {
	return http<StorePromoBannerResponse>(`store-promo-banners/${banner_id}`, "PUT", data)
}

export const deleteStorePromoBanner = (banner_id: number) => {
	return http<StorePromoBannerResponse>(`store-promo-banners/${banner_id}`, "DELETE")
}

export const updateStorePromoBannerImage = (banner_id: number, image: File, data: { file_type: "image" | "video" }) => {
	return multipart(`store-promo-banners/${banner_id}/media`, "PUT", "media", image, data)
}