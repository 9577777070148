import React from 'react'
import { TextField, TextFieldProps } from "@mui/material"

type Props = {

} & TextFieldProps

const CTextField = (props: Props) => {
	return (
		<TextField {...props} />
	)
}

export default CTextField
