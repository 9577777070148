import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import CButton from '../../Components/CButton'
import CTextField from '../../Components/CTextField'
import Line from '../../Components/Line'
import MultiLangInput from '../../Components/MultiLangInput'
import PhotoPicker from '../../Components/PhotoPicker'
import SegmentedControl from '../../Components/SegmentedControl'
import { colors } from '../../Global/colors'
import { container } from '../../Global/styles'
import { genericError, successToast } from '../../Global/toast'
import { isoDate } from '../../Global/util'
import { editConfig, getConfigs } from '../../Network/configs'
import { createStorePromoBanner, getStorePromoBanners, updateStorePromoBanner, updateStorePromoBannerImage } from '../../Network/store.promo'
import { Configs } from '../../petal-common/Models/Configs'
import { LocalizedString } from '../../petal-common/Models/Generic'
import { ProductSection } from '../../petal-common/Models/Product'
import { StorePromoBanner } from '../../petal-common/Models/StorePromoBanner'
import sections from '../StoreProductsSectionsPage/sections'

const ConfigsPage = () => {

	const [configs, setConfigs] = useState<Configs>()
	const [storePromoBanner, setStorePromoBanner] = useState<StorePromoBanner>()

	const [editedPhoto, setEditedPhoto] = useState<File>()
	const [editedDisplayPhoto, setEditedDisplayPhoto] = useState<string>()
	const [bannerMediaType, setBannerMediaType] = useState<"image" | "video">()
	const [editedStartDate, setEditedStartDate] = useState<Date>()
	const [editedEndDate, setEditedEndDate] = useState<Date>()
	const [editedCaption, setEditedCaption] = useState<LocalizedString>()
	const [editedSelectedSection, setEditedSelectedSection] = useState<ProductSection>()

	const [configsSaveLoading, setCongigsSaveLoading] = useState(false)
	const [bannerSaveLoading, setBannerSaveLoading] = useState(false)

	useEffect(() => {
		loadData()
	}, [])

	const loadData = async () => {
		try {
			const configsResponse = await getConfigs()
			if (configsResponse.success) {
				setConfigs(configsResponse.data?.configs)
			} else {
				genericError()
			}

			const promoBannersResponse = await getStorePromoBanners()
			if (promoBannersResponse.success) {
				setStorePromoBanner(promoBannersResponse.data?.store_promo_banners[0])
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
	}

	const onconfigsSave = async () => {
		if (!configs) return
		try {
			setCongigsSaveLoading(true)
			for (const conf of Object.entries(configs)) {
				const response = await editConfig(conf[0], conf[1])
				if (!response.success) {
					genericError()
					break
				}
			}

			successToast("Impostazioni salvate con successo")
		} catch (e) {
			genericError()
		}
		setCongigsSaveLoading(false)
	}

	const onStorePromoBannerSave = async () => {
		try {
			setBannerSaveLoading(true)
			const payload: Partial<StorePromoBanner> = {
				caption: editedCaption || storePromoBanner?.caption,
				from_date: (editedStartDate ? isoDate(editedStartDate) : storePromoBanner?.from_date) || isoDate(new Date()),
				to_date: (editedEndDate ? isoDate(editedEndDate) : storePromoBanner?.to_date) || isoDate(new Date()),
				store_section: editedSelectedSection || storePromoBanner?.store_section || "flowers"
			}
			let response
			if(storePromoBanner) {
				response = await updateStorePromoBanner(storePromoBanner.id, payload)
			} else {
				response = await createStorePromoBanner(payload)
			}

			if(!response.success) {
				genericError()
				return
			}

			const banner = response.data?.store_promo_banner

			if(banner && editedPhoto) {
				const mediaResponse = await updateStorePromoBannerImage(banner.id, editedPhoto, {
					file_type: bannerMediaType!
				})
				if(!mediaResponse.success) {
					genericError()
					return
				}
			}

			if(banner) reset(banner)
		} catch(e) {
			genericError()
		}
		setBannerSaveLoading(false)
	}

	const reset = (banner: StorePromoBanner) => {
		setStorePromoBanner(banner)
		setEditedCaption(undefined)
		setEditedStartDate(undefined)
		setEditedEndDate(undefined)
		setEditedSelectedSection(undefined)
	}

	return (
		<div style={{ ...container }}>
			{configs && (
				<div style={{ marginTop: 20, marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ fontWeight: 'bold', marginBottom: 30, fontSize: 20 }}>Impostazioni</div>
						<CTextField label="Scadenza fiori gratuiti" value={configs.config_key_free_flower_duration} onChange={e => setConfigs({ ...configs, config_key_free_flower_duration: e.target.value })} />
						<CTextField style={{ marginTop: 20 }} label="Scadenza fiori a pagamento" value={configs.config_key_flower_duration} onChange={e => setConfigs({ ...configs, config_key_flower_duration: e.target.value })} />
						<CTextField style={{ marginTop: 20 }} label="Scadenza led" value={configs.config_key_led_duration} onChange={e => setConfigs({ ...configs, config_key_led_duration: e.target.value })} />
						<CTextField style={{ marginTop: 20 }} label="Scadenza regali" value={configs.config_key_gift_duration} onChange={e => setConfigs({ ...configs, config_key_gift_duration: e.target.value })} />
						<CTextField style={{ marginTop: 20 }} label="Prodotti gratuiti iniziali" value={configs.config_key_free_initial_flowers} onChange={e => setConfigs({ ...configs, config_key_free_initial_flowers: e.target.value })} />
					</div>
					<CButton loading={configsSaveLoading} style={{ margin: "20px 0px" }} label='Salva' onClick={onconfigsSave} />
				</div>
			)}
			<Line/>
			<div style={{ display: "flex", flex: 1, margin: 20, flexDirection: 'column', alignItems: 'center' }}>
				<div style={{ fontWeight: 'bold', marginBottom: 10, fontSize: 20 }}>Banner promozionale nello store</div>
				<div style={{ flex: 1, marginBottom: 10 }}>
					<PhotoPicker
						hideRemove
						pickerLayout='column'
						imageHeight={384}
						imageWidth={512}
						placeholderImage={"https://placehold.co/1024x768"}
						displayMedia={editedDisplayPhoto || storePromoBanner?.image || storePromoBanner?.video}
						displayMediaType={bannerMediaType || (storePromoBanner?.video ? "video" : "image")}
						onMediaSelected={(media, display, type) => {
							console.log(media, display)
							setEditedPhoto(media)
							setEditedDisplayPhoto(display)
							setBannerMediaType(type)
						}}
					/>
				</div>
				<div style={{ flex: 1, display: 'flex', flexDirection: "column", marginBottom: 20 }}>
					<div style={{ display: 'flex' }}>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
							<div>Data di inizio</div>
							<DatePicker value={editedStartDate || moment(storePromoBanner?.from_date).toDate()} renderInput={(props) => (
								<CTextField {...props} />
							)} inputFormat="DD/MM/YYYY" onChange={(date) => setEditedStartDate(moment(date).toDate())} />
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 10 }}>
							<div>Data di fine</div>
							<DatePicker value={editedEndDate || moment(storePromoBanner?.to_date)} renderInput={(props) => (
								<CTextField {...props} />
							)} inputFormat="DD/MM/YYYY" onChange={(date) => setEditedEndDate(moment(date).toDate())} />
						</div>
					</div>
					{/* <CTextField style={{ marginTop: 15 }} label="Testo" value={editedCaption || storePromoBanner?.caption || ""} onChange={e => setEditedCaption(e.target.value)} /> */}
					<MultiLangInput title="Testo" value={editedCaption || storePromoBanner?.caption} onChangeValue={setEditedCaption}/>
					<div style={{ marginTop: 15, display: 'flex', flexDirection: 'column' }}>
						<div style={{ marginBottom: 10 }}>Sezione a cui mandare</div>
						<SegmentedControl
							minItemWidth={100}
							items={sections}
							value={editedSelectedSection || storePromoBanner?.store_section || "flowers"}
							onChange={(val) => setEditedSelectedSection(val as ProductSection)}
						/>
					</div>
				</div>
				<CButton disabled={
					/* !(storePromoBanner?.caption || editedCaption) || */
					!(storePromoBanner?.image || editedPhoto)
				} loading={bannerSaveLoading} style={{ width: 300 }} label='Salva' onClick={onStorePromoBannerSave}/>
			</div>
			<Toaster />
		</div>
	)
}

export default ConfigsPage
