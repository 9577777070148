import { http } from "."
import { Configs } from "../petal-common/Models/Configs"

export type GetConfigsResponse = {
	configs: Configs
}
export const getConfigs = () => {
	return http<GetConfigsResponse>("configs")
}

export const editConfig = (key: string, value: string) => {
	return http<GetConfigsResponse>(`configs/${key}`, "PUT", {
		value
	})
}