import { http, loadUrl } from "."
import { SocialDeleteWill } from "../petal-common/Models/SocialDeleteWill"

type GetSocialDeleteWillsResponse = {
	social_delete_wills: SocialDeleteWill[]
	count: number
	rows: number
}
export const getSocialDeleteWills = (limit: number, offset: number) => {
	return http<GetSocialDeleteWillsResponse>(loadUrl("social-delete-wills", {
		limit,
		offset
	}))
}