import React, { useEffect, useState } from 'react'
import { container } from '../../Global/styles'
import { Checkbox, CircularProgress } from '@mui/material'
import { SocialDeleteWill } from '../../petal-common/Models/SocialDeleteWill'
import { usePagination } from '../../Global/hooks'
import { DEFAULT_PAGE_SIZE } from '../../Global/util'
import { genericError } from '../../Global/toast'
import { getSocialDeleteWills } from '../../Network/social-delete-wills'
import { translate } from '../../petal-client-common/Translation'
import CTable from '../../Components/CTable'
import { userDisplayName } from '../../petal-common/Models/User'
import CButton from '../../Components/CButton'

const SocialDeleteWills = () => {

	const [loading, setLoading] = useState(true)
	const [data, setData] = useState<SocialDeleteWill[]>([])
	const pagination = usePagination()

	useEffect(() => {
		fetchData(DEFAULT_PAGE_SIZE, 0)
	}, [])

	const fetchData = async (limit: number, offset: number) => {
		setLoading(true)
		try {
			const response = await getSocialDeleteWills(limit, offset * limit)
			if (response.success) {
				setData(response.data?.social_delete_wills || [])
				pagination.setCount(response.data?.count || 0)
				pagination.setRows(response.data?.rows || 0)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setLoading(false)
	}

	const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
		pagination.setPage(newPage)
		fetchData(DEFAULT_PAGE_SIZE, newPage)
	}
	
	if (loading) {
		return (
			<div style={{ ...container, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
				<CircularProgress size={36} />
			</div>
		)
	}

	return (
		<div style={{ ...container, paddingTop: 10, flexDirection: 'column', justifyContent: 'flex-start' }}>
			<CTable<SocialDeleteWill>
				data={data}
				headerLabels={[
					{ label: "" },
					{ label: "User id" },
					{ label: translate("email") },
					{ label: translate('first_name') },
					{ label: translate('social_name') },
					{ label: "" }
				]}
				rowCells={(will) => [
					{ content: <img style={{ height: 50, width: 50, objectFit: "contain" }} src={will.user?.profile_image_thumb ? will.user?.profile_image_thumb : '/assets/generic-placeholder.png'}/> },
					{ content: will.user_id },
					{ content: will.user?.email },
					{ content: userDisplayName(will.user) },
					{ content: will.social },
					{
						content: <div style={{ display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'flex-end' }}>
							<CButton label={translate("view")} onClick={() => {
								if(will.video) {
									window.open(will.video, "_blank")
								}
							}} />
						</div>
					}
				]}
				pagination={pagination}
				onPageChange={onPageChange}
			/>
		</div>
	)
}

export default SocialDeleteWills
