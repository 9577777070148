import React, { useEffect, useMemo, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import CTable from '../../Components/CTable'
import CTextField from '../../Components/CTextField'
import PageLoading from '../../Components/PageLoading'
import { usePagination, useUser } from '../../Global/hooks'
import { container } from '../../Global/styles'
import { genericError } from '../../Global/toast'
import { DEFAULT_PAGE_SIZE, humanDate } from '../../Global/util'
import { getPeople } from '../../Network/people'
import { translate } from '../../petal-client-common/Translation'
import { Person, personDisplayName } from '../../petal-common/Models/Person'
import { User, userDisplayName } from '../../petal-common/Models/User'
import { Button } from '@mui/material'
import AssignRelative from '../../Components/AssignRelative'

const PeopleList = () => {

	const self: {
		search: string
	} = useMemo(() => {
		return {
			search: "",
		}
	}, [])
	
	const user = useUser()
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState<Person[]>([])
	const [search, setSearch] = useState("")
	const [modalPerson, setModalPerson] = useState<Person | null>(null)

	const pagination = usePagination()

	useEffect(() => {
		if(user) {
			fetchData(DEFAULT_PAGE_SIZE, 0)
		}
	}, [user])

	const refreshData = async () => {
		fetchData(DEFAULT_PAGE_SIZE, pagination.page)
	}
	const fetchData = async (limit: number, offset: number) => {
		setLoading(true)
		try {
			const response = await getPeople({
				limit,
				offset: offset * limit,
				query: self.search,
				order_by: "created_at",
				order_mode: "DESC",
				with_relative: true,
				user_id: user?.role == "manager" ? user.id : undefined
			})
			console.log(response)
			if (response.success) {
				setData(response.data?.people || [])
				pagination.setCount(response.data?.people_count || 0)
				pagination.setRows(response.data?.rows || 0)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setLoading(false)
	}

	const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
		pagination.setPage(newPage)
		fetchData(DEFAULT_PAGE_SIZE, newPage)
	}

	const onSearch = (text: string) => {
		self.search = text
		setSearch(text)
		setTimeout(() => {
			if (text == self.search) {
				fetchData(DEFAULT_PAGE_SIZE, 0)
			}
		}, 500)
	}

	if(loading) {
		return <PageLoading/>
	}

	return (
		<div style={{ ...container, flexDirection: 'column', justifyContent: "flex-start" }}>
			<CTextField style={{ width: 500, margin: "10px 0px 0px 10px" }} value={search} label="Cerca" onChange={(e) => onSearch(e.target.value)}/>
			<CTable<Person>
				data={data}
				headerLabels={[
					{ label: "" },
					{ label: translate('first_name') },
					{ label: translate('creation_date') },
					{ label: translate('creator') },
					{ label: "amministratore" },
				]}
				rowCells={(person) => [
					{ content: <img style={{ height: 50, width: 50, objectFit: "contain" }} src={person.profile_image_thumb ? person.profile_image_thumb : '/assets/generic-placeholder.png'}/> },
					{ content: personDisplayName(person) },
					{ content: humanDate(person.created_at) },
					{ content: `${userDisplayName(person.creator)} (${person.creator?.email})` },
					{ content: <Button onClick={() => setModalPerson(person)}>{`${userDisplayName(person.relative) || "Assegna"}`}</Button> },

				]}
				pagination={pagination}
				onPageChange={onPageChange}
			/>
			<AssignRelative relative={modalPerson?.relative ?? null} person={modalPerson} open={modalPerson != null} onFinish={() => {
				setModalPerson(null)
				refreshData().then(console.log)
			}}/>
			<Toaster />
		</div>
	)
	
}

export default PeopleList
