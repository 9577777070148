import React, { useEffect, useMemo, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import CButton from '../../Components/CButton'
import CTable from '../../Components/CTable'
import CTextField from '../../Components/CTextField'
import PageLoading from '../../Components/PageLoading'
import { usePagination } from '../../Global/hooks'
import { container } from '../../Global/styles'
import { genericError, successToast } from '../../Global/toast'
import { DEFAULT_PAGE_SIZE, humanDate, webPath } from '../../Global/util'
import config from '../../Network/config'
import { blockUser, downloadAllEmails, getUsers } from '../../Network/users'
import { translate } from '../../petal-client-common/Translation'
import { ItemsMap } from '../../petal-common/Models/Generic'
import { User, userDisplayName } from '../../petal-common/Models/User'
import CModal from '../../Components/CModal'

const UsersList = () => {

	const self: {
		search: string
	} = useMemo(() => {
		return {
			search: "",
		}
	}, [])

	const navigate = useNavigate()

	const [userEmails, setUserEmails] = useState<string[]>([])
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState<User[]>([])
	const [search, setSearch] = useState("")
	const [loadings, setLoadings] = useState<ItemsMap<{ block?: boolean, delete?: boolean }>>({})
	const pagination = usePagination()

	useEffect(() => {
		fetchData(DEFAULT_PAGE_SIZE, 0)
	}, [])

	const fetchData = async (limit: number, offset: number) => {
		setLoading(true)
		try {
			const response = await getUsers({
				limit,
				offset: offset * limit,
				query: self.search,
				reverse_order: true,
			})
			if (response.success) {
				setData(response.data?.users || [])
				pagination.setCount(response.data?.count || 0)
				pagination.setRows(response.data?.rows || 0)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setLoading(false)
	}

	const onBlock = (user: User) => {
		updateUser(user, true)
	}

	const onUnlock = (user: User) => {
		updateUser(user, false)
	}

	const updateUser = async (user: User, blockStatus: boolean) => {
		setLoadings({
			[`${user.id}`]: { block: true }
		})

		const response = await blockUser(user.id, blockStatus)
		if (response.success) {
			fetchData(pagination.rows, pagination.page)
			successToast(`Utente ${blockStatus ? "bloccato" : "sbloccato"} con successo`)
		} else {
			genericError()
		}

		setLoadings({})
	}

	const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
		pagination.setPage(newPage)
		fetchData(DEFAULT_PAGE_SIZE, newPage)
	}

	const onSearch = (text: string) => {
		self.search = text
		setSearch(text)
		setTimeout(() => {
			if (text == self.search) {
				fetchData(DEFAULT_PAGE_SIZE, 0)
			}
		}, 500)
	}

	const downloadEmails = async () => {
		try {
			const response = await downloadAllEmails()
			if(response.success) {
				setUserEmails(response.data?.emails || [])
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
	}

	if (loading) {
		return <PageLoading />
	}

	return (
		<div style={{ ...container, flexDirection: 'column', justifyContent: "flex-start" }}>
			<div style={{ display: 'flex', alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center', paddingRight: 50 }}>
				<CTextField style={{ width: 500, margin: "10px 0px 0px 10px" }} value={search} label="Cerca" onChange={(e) => onSearch(e.target.value)} />
				<CButton label='Tutte le email' onClick={downloadEmails}/>
			</div>
			<CTable<User>
				data={data}
				headerLabels={[
					{ label: "" },
					{ label: translate('first_name') },
					{ label: translate('account_type') },
					{ label: translate('creation_date') },
					{ label: translate('percentage_applied') },
					{ label: translate('email') },
					{ label: translate('city') },
					{ label: translate('country') },
					{ label: "" }
				]}
				rowCells={(user) => [
					{ content: <img style={{ height: 50, width: 50, objectFit: "contain" }} src={user.profile_image_thumb ? user.profile_image_thumb : '/assets/generic-placeholder.png'} /> },
					{ content: userDisplayName(user) },
					{ content: user.role == 'admin' ? "Amministratore" : user.role == 'user' ? translate('user') : translate('manager') },
					{ content: humanDate(user.created_at) },
					{ content: user.role == 'manager' ? (user.revenue_percentage + "%") : "-" },
					{ content: user.email },
					{ content: user.city },
					{ content: user.country?.name },
					{
						content: <div style={{ display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'flex-end' }}>
							<CButton loading={loadings?.[user.id]?.block} style={{ width: 180 }} label={translate(user.blocked ? 'unlock' : 'block')} onClick={() => {
								if (user.blocked) {
									onUnlock(user)
								} else {
									onBlock(user)
								}
							}} />
							{/* <CButton loading={loadings?.[user.id]?.delete} style={{ marginTop: 5, width: "100%" }} label={translate('delete')} onClick={() => {

							}} /> */}
							{user.role == 'manager' && <CButton style={{ marginTop: 5, width: 180 }} label={"Dashboard"} onClick={() => {
								window.open(config.web_app + "/web/admin/manager/" + user.id + "/dashboard", "_blank")
							}} />}
						</div>
					}
				]}
				pagination={pagination}
				onPageChange={onPageChange}
			/>
			<CModal title='Email utenti' open={userEmails.length > 0} onClose={() => setUserEmails([])} actions={[
				{
					label: "Chiudi",
					onPress: () => {
						setUserEmails([])
					}
				}, {
					label: "Copia",
					onPress: () => {
						navigator.clipboard.writeText(userEmails.join("\n"))
						successToast("Email copiate negli appunti")
					}
				}
			]}>
				<div style={{ maxHeight: 600, overflow: 'auto' }}>
					<div></div>
					{userEmails.map(e => (
						<div key={e}>
							{e}
						</div>
					))}
				</div>
			</CModal>
			<Toaster />
		</div>
	)
}

export default UsersList
