import React, { ReactNode } from 'react'
import Nestable, {Item} from 'react-nestable';

type Props<T> = {
	data: T[]
	renderItem: (item: T) => ReactNode
	map: (item: T) => Item
	onChange: (items: T[]) => void
}

const DraggableList = <T extends unknown>(props: Props<T>) => {

	return (
		<div>
			<Nestable
				maxDepth={1}
				items={props.data.map(item => props.map(item))}
				renderItem={(item) => {
					const t_item = props.data.find(t_item => props.map(t_item).id == item.item.id)
					if(t_item) return props.renderItem(t_item)
					return <div/>
				}}
				onChange={({ items }) => {
					props.onChange(items.map(item => props.data.find(t_item => props.map(t_item).id == item.id)!))
				}}
			/>
		</div>
	)
}

export default DraggableList
