import { CircularProgress } from '@mui/material'
import React from 'react'
import { container } from '../../Global/styles'

const PageLoading = () => {
	return (
		<div style={{ ...container, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
			<CircularProgress size={36} />
		</div>
	)
}

export default PageLoading
