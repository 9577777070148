import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Sidebar from '../../Components/Sidebar'
import { adminNavigation } from '../../RoutesConfig/admin.navigation'
import sidebarItems from './sidebarItems'

const AdminRouter = () => {
	return (
		<div style={{ display: 'flex', height: "100vh", width: "100vw" }}>
			<Sidebar items={sidebarItems} />
			<div style={{ display: 'flex', flex: 1, overflow: 'auto' }}>
				<Routes>
					{adminNavigation.map((item, index) => (
						<Route
							key={index}
							path={item.path}
							element={item.element}
						/>
					))}
				</Routes>
			</div>
		</div>
	)
}

export default AdminRouter
