import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { container } from '../../Global/styles'
import { genericError, successToast } from '../../Global/toast'
import { AbuseReport } from '../../petal-common/Models/AbuseReport'
import { deleteReportedElement, getAbuseReport, updateAbuseReportStatus } from '../../Network/abuse.reports'
import PageLoading from '../../Components/PageLoading'
import { useNavigate, useParams } from 'react-router-dom'
import CButton from '../../Components/CButton'
import ActionDetail from '../../Components/ActionDetail'
import Line from '../../Components/Line'
import UserCell from '../../Components/UserCell'

const AbuseReportsDetail = () => {

	useEffect(() => {
		fetchData()
	}, [])

	const navigate = useNavigate()
	const pathParams = useParams();
	const reportId = pathParams.id

	const [data, setData] = useState<AbuseReport>()
	const [loading, setLoading] = useState(true)
	const [deleteElementLoading, setDeleteElementLoading] = useState(false)
	const [rejectReportLoading, setRejectReportLoading] = useState(false)

	const fetchData = async () => {
		if (!reportId) return
		setLoading(true)
		try {
			const response = await getAbuseReport(+reportId)
			if (response.success) {
				setData(response.data?.abuse_report)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setLoading(false)
	}

	const onDeleteElementClick = async () => {
		if (!reportId) return

		setDeleteElementLoading(true)

		try {
			const response = await deleteReportedElement(+reportId)
			if (response.success) {
				successToast("Elemento eliminato con successo")
				navigate(-1)
			} else {
				throw new Error()
			}
		} catch (e) {
			genericError()
		}

		setDeleteElementLoading(false)
	}

	const onRejectReportClick = async () => {
		if (!reportId) return

		setRejectReportLoading(true)

		try {
			const response = await updateAbuseReportStatus(+reportId, "discarded")
			if (response.success) {
				successToast("Operazione completata")
				navigate(-1)
			} else {
				throw new Error()
			}
		} catch (e) {
			genericError()
		}

		setRejectReportLoading(false)
	}

	const notExistingItem = !data?.action && !data?.comment

	if (loading) {
		return <PageLoading />
	}

	return (
		<div style={{ ...container, flexDirection: 'column' }}>
			<div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 20, overflow: 'auto' }}>
				<div style={{ display: 'flex' }}>
					{data?.action && <ActionDetail style={{}} action={data.action} />}
					{data?.comment && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
						{/* @ts-ignore */}
						<UserCell user={data.comment.user}/>
						{/* @ts-ignore */}
						<div style={{ marginTop: 10 }}>{data.comment.comment}</div>
					</div>}
					{notExistingItem && <div style={{ flex: 1 }}>Elemento non più esistente.</div>} 
					<Line />
					{data?.motivation && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
						<div style={{ fontWeight: 'bold' }}>Motivo della segnalazione</div>
						<div style={{ marginTop: 10, overflowY: 'scroll', alignSelf: 'stretch', textAlign: 'start' }}>{data.motivation}</div>
					</div>}
				</div>
			</div>
			<div style={{ flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 20 }}>
				<CButton disabled={notExistingItem} loading={deleteElementLoading} style={{ marginRight: 20 }} label='Elimina elemento' onClick={onDeleteElementClick} />
				<CButton loading={rejectReportLoading} label="Rifiuta segnalazione" onClick={onRejectReportClick} />
			</div>
			<Toaster />
		</div>
	)
}

export default AbuseReportsDetail
