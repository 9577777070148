import React, { ReactNode } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import CCard from '../CCard'
import { DEFAULT_PAGE_SIZE } from '../../Global/util'
import { Pagination } from '../../Global/hooks'

type HeaderLabel = {
	label: ReactNode
}

type RowCells = {
	content: ReactNode
}

type Props<T> = {
	data: T[]
	headerLabels: HeaderLabel[]
	rowCells: (item: T) => RowCells[]
	pagination?: Pagination
	onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void
}

const CTable = <T extends unknown>(props: Props<T>) => {
	return (
		<CCard variant='elevation' style={{ flex: 1 }}>
			<div style={{ display: "flex", flexDirection: 'column', flex: 1 }}>
				<TableContainer style={{ flex: 1 }} component={"div"}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								{props.headerLabels.map((item, i) => (
									<TableCell key={i} style={{ fontWeight: 'bold' }}>{item.label}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{(props.data || []).map((item, i) => (
								<TableRow key={i}>
									{
										props.rowCells(item).map((cell, j) => (
											<TableCell key={i + "." + j}>{cell.content}</TableCell>
										))
									}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{props.pagination && <TablePagination
					component="div"
					rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
					count={props.pagination.count}
					rowsPerPage={props.pagination.rows}
					page={props.pagination.page}
					onPageChange={props.onPageChange}
					onRowsPerPageChange={() => { }}
				/>}
			</div>
		</CCard>
	)
}

export default CTable
