import getUserLocale from "get-user-locale"
import moment from "moment"
import { DailyAnalytics, GainData, Gains } from "../petal-common/Models/Analytics"
import { LocalizedString } from "../petal-common/Models/Generic"
import supportedLanguages from "../petal-common/supportedLanguages"

export const DEFAULT_PAGE_SIZE = 20

export const webPath = (path?: string) => {
	if(!path) return "/web"
	return "/web" + path
}

export const ENTRY_POINT = webPath("/")

export const localizedStringValue = (label: LocalizedString) => {
	let res = label[getUserLocale()]
	if(!res) {
		res = label["en"]
	} 
	return res || ""
}

export const isLocalizedStringFullFilled = (string: LocalizedString) => {
	return supportedLanguages.reduce((acc, item) => {
		return acc && !!string[item.code]
	}, true)
}

export const humanDate = (date?: string | Date) => {
	if(!date) date = new Date()
	return moment(date).format("DD/MM/yyyy HH:mm")
}

export const formatPrice = (price: number, withSymbol?: boolean) => {
	return `${(price / 100).toFixed(2)}${withSymbol ? " €" : ""}`
}

export const isoDate = (date: string | moment.Moment | Date) => {
	date = moment(date)
	return date.format("yyyy-MM-DD")
}

export const clone = (obj: any) => {
	return JSON.parse(JSON.stringify(obj))
}

export const sumGain = (analytics: DailyAnalytics[]) => {
	const result: Gains = {}
	analytics.forEach((day) => {
		const gains = day.gains
		Object.entries(gains).forEach(([currency, gains]) => {
			if(result[currency]) {
				result[currency]!.gross += (gains?.gross || 0)
				result[currency]!.net_from_store += (gains?.net_from_store || 0)
				result[currency]!.net += (gains?.net || 0)
			} else {
				result[currency] = clone(gains)
			}
		})
	})

	return result
}