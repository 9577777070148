import React from 'react'
import { Wallpaper } from '../../../petal-common/Models/Wallpaper'
import CCard from '../../CCard'
import IconButton from '../../IconButton'
import * as MDIcons from "react-icons/md"
import { humanDate } from '../../../Global/util'
import { colors } from '../../../Global/colors'

type Props = {
	wallpaper: Wallpaper
	onEditClick: (wallpaper: Wallpaper) => void
	onDeleteClick: (wallpaper: Wallpaper) => void
}

const WallpaperCell = (props: Props) => {
	return (
		<CCard style={{ margin: 0, alignSelf: 'stretch', display: 'flex' }}>
			<div style={{ display: 'flex', flex: 1 }}>
				<img style={{ height: 100, width: 100, objectFit: "contain", marginLeft: 10 }} src={props.wallpaper.photo ? props.wallpaper.photo : '/assets/generic-placeholder.png'} />
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', minWidth: 400, marginLeft: 20 }}>
					<div style={{ display: 'flex', alignItems: 'center', alignSelf: 'stretch' }}>
						<div style={{ fontSize: 20, fontWeight: 'bold', flex: 1, textAlign: 'start' }}>{props.wallpaper.product_store_id}</div>
						<div style={{ marginLeft: 20 }}>
							<IconButton icon={<MDIcons.MdEdit onClick={() => props.onEditClick(props.wallpaper)} />} />
							<IconButton icon={<MDIcons.MdDelete onClick={() => props.onDeleteClick(props.wallpaper)} />} />
						</div>
					</div>
					<div style={{ fontSize: 16, marginTop: 5 }}>Creato il: {humanDate(props.wallpaper.created_at)}</div>
				</div>
				<div style={{ height: '100%', width: "1px", backgroundColor: colors.lightGrey, margin: "0px 20px" }} />
			</div>
			<div style={{ fontWeight: 'bold', fontSize: 20, minWidth: 150, textAlign: 'end' }}>{props.wallpaper.is_free ? "Gratis" : "A pagamento"}</div>
		</CCard>
	)
}

export default WallpaperCell
