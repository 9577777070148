import React, { ReactNode, useRef } from 'react'
import CButton from '../CButton'

type MediaType = "image" | "video"

type Props = {
	displayMedia?: string
	displayMediaType?: MediaType
	onMediaSelected: (media: File, displayMedia: string, mediaType: MediaType) => void
	onMediaRemoved?: () => void
	hideRemove?: boolean
	placeholderImage?: string
	imageWidth?: number
	imageHeight?: number
	pickerLayout?: "row" | "column"
}

const PhotoPicker = (props: Props) => {

	const inputFile = useRef<HTMLInputElement>(null)

	const onChooseMediaClicked = () => {
		inputFile.current?.click()
	}

	const onMediaChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		if (event.target.files && event.target.files[0]) {
			const mediaType = event.target.files[0].type.includes("video") ? "video" : "image"
			props.onMediaSelected(event.target.files[0], URL.createObjectURL(event.target.files[0]), mediaType)
		}
	}

	let displayComponent: ReactNode
	if (props.displayMediaType == "video") {
		if (!props.displayMedia && props.placeholderImage) {
			<img style={{ height: props.imageHeight || 100, width: props.imageWidth || 100, objectFit: "contain" }} src={props.placeholderImage} />
		} else {
			displayComponent = <video width={props.imageWidth || 100} height={props.imageHeight || 100} controls >
				<source src={props.displayMedia} type="video/mp4" />
			</video>
		}
	} else {
		displayComponent = <img style={{ height: props.imageHeight || 100, width: props.imageWidth || 100, objectFit: "contain" }} src={props.displayMedia ? props.displayMedia : (props.placeholderImage || '/assets/generic-placeholder.png')} />
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center', marginTop: 20, flexDirection: props.pickerLayout }}>
			{displayComponent}
			<div style={{ display: 'flex', flexDirection: 'column', margin: 20 }}>
				<input ref={inputFile} style={{ display: 'none' }} type="file" onChange={onMediaChange} className="filetype" />
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<CButton style={{ alignSelf: 'flex-end', minWidth: 120 }} label="Carica" onClick={onChooseMediaClicked} />
					{!props.hideRemove && <CButton disabled={!props.displayMedia} style={{ alignSelf: 'flex-end', marginTop: 10, minWidth: 120 }} label="Rimuovi" onClick={props.onMediaRemoved} />}
				</div>
			</div>
		</div>
	)
}

export default PhotoPicker
