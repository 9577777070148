import React from 'react'
import { cardTitle } from '../../../Global/styles'
import { Product, ProductCategory } from '../../../petal-common/Models/Product'
import CButton from '../../CButton'
import CCard from '../../CCard'
import * as MDIcons from "react-icons/md";
import IconButton from '../../IconButton'
import { localizedStringValue } from '../../../Global/util'
import ProductCell from '../ProductCell'
import { colors } from '../../../Global/colors'

type Props = {
	category: ProductCategory
	onEditPress: (category: ProductCategory) => void
	onDeletePress: (category: ProductCategory) => void
	onAddProductPress: (category: ProductCategory) => void
	onEditProductClick: (product: Product) => void
	onDeleteProductClick: (product: Product) => void
}

const ProductCategoryCell = (props: Props) => {

	const onAddProductClick = () => {
		props.onAddProductPress(props.category)
	}

	const onEditCategoryClick = () => {
		props.onEditPress(props.category)
	}

	const onDeleteCategoryClick = () => {
		props.onDeletePress(props.category)
	}



	return (
		<CCard style={{ backgroundColor: colors.veryLightGrey }}>
			<div style={{ flexDirection: 'column', width: '100%' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
						<div style={{ ...cardTitle, textAlign: 'start' }}>{localizedStringValue(props.category.label)}</div>
						<IconButton style={{ marginLeft: 10 }} icon={<MDIcons.MdEdit />} onClick={onEditCategoryClick} />
						<IconButton icon={<MDIcons.MdDelete />} onClick={onDeleteCategoryClick} />
					</div>
					<CButton startIcon={<MDIcons.MdAdd />} label='Aggiungi prodotto' onClick={onAddProductClick} />
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start', marginTop: 10 }}>
					{(props.category.products || []).map((p, i) => (
						<div key={i} style={{ alignSelf: 'stretch' }}>
							{i != 0 && <div style={{ height: 10 }}/>}
							<ProductCell
								product={p}
								onEditClick={props.onEditProductClick}
								onDeleteClick={props.onDeleteProductClick}
							/>
						</div>
					))}
					{(props.category.products || []).length == 0 && <div style={{ alignSelf: 'center', margin: "20px 0px" }}>Non hai ancora aggiunto nessun prodotto in questa categoria</div>}
				</div>
			</div>
		</CCard>
	)
}

export default ProductCategoryCell
