import { http, loadUrl, multipart } from "."
import { Product, ProductCategory, ProductSection } from "../petal-common/Models/Product"

type GetProductCategories = {
	product_categories: ProductCategory[]
}
export const getProductCategories = (section: ProductSection) => {
	const url = loadUrl("product-categories", {
		section
	})
	return http<GetProductCategories>(url)
}

type GetProductCategory = {
	product_category: ProductCategory
}
export const createProductCategory = (data: Partial<ProductCategory>) => {
	return http<GetProductCategory>("product-categories", "POST", data)
}

export const updateProductCategory = (category_id: number, data: Partial<ProductCategory>) => {
	return http<GetProductCategory>(`product-categories/${category_id}`, "PUT", data)
}

export const deleteProductCategory = (category_id: number) => {
	return http(`product-categories/${category_id}`, "DELETE")
}

type GetProductResponse = {
	product: Product
}
export const createProduct = (category_id: number, data: Partial<Product>) => {
	return http<GetProductResponse>(`products`, "POST", {
		category_id,
		...data
	})
}

export const updateProduct = (product_id: number, data: Partial<Product>) => {
	return http<GetProductResponse>(`products/${product_id}`, "PUT", {
		...data
	})
}

export const deleteProduct = (product_id: number) => {
	return http(`products/${product_id}`, "DELETE")
}

export const uploadProductPhoto = (product_id: number, image: File) => {
	return multipart(`products/${product_id}/media`, "PUT", "media", image)
}

export const reorderProductCategories = (section: ProductSection, newOrder: { category_id: number, order: number }[]) => {
	return http<GetProductCategories>(`/product-categories/reorder`, "PUT", {
		new_order: newOrder,
		product_section: section
	})
}