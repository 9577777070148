import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { container } from '../../Global/styles'
import { genericError, successToast } from '../../Global/toast'
import CTable from '../../Components/CTable'
import { AbuseReport } from '../../petal-common/Models/AbuseReport'
import { usePagination } from '../../Global/hooks'
import { translate } from '../../petal-client-common/Translation'
import { userDisplayName } from '../../petal-common/Models/User'
import { DEFAULT_PAGE_SIZE, humanDate, webPath } from '../../Global/util'
import CButton from '../../Components/CButton'
import { getAbuseReports } from '../../Network/abuse.reports'
import PageLoading from '../../Components/PageLoading'
import { useNavigate } from 'react-router-dom'

const AbuseReportsList = () => {

	useEffect(() => {
		fetchData(DEFAULT_PAGE_SIZE, 0)
	}, [])

	const navigate = useNavigate()
	const [data, setData] = useState<AbuseReport[]>([])
	const [loading, setLoading] = useState(true)
	const pagination = usePagination()

	const fetchData = async (limit: number, offset: number) => {
		setLoading(true)
		try {
			const response = await getAbuseReports(limit, offset * limit)
			if (response.success) {
				setData(response.data?.abuse_reports || [])
				pagination.setCount(response.data?.count || 0)
				pagination.setRows(response.data?.rows || 0)
			} else {
				genericError()
			}
		} catch (e) {
			genericError()
		}
		setLoading(false)
	}

	const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
		pagination.setPage(newPage)
		fetchData(DEFAULT_PAGE_SIZE, newPage)
	}

	if(loading) {
		return <PageLoading/>
	}

	return (
		<div style={{ ...container }}>
			<CTable<AbuseReport>
				data={data}
				headerLabels={[
					{ label: translate("report_entity") },
					{ label: translate('reporter') },
					{ label: translate('report_date') },
					{ label: "" }
				]}
				rowCells={(report) => [
					{ content: report.action_id ? translate("post") : translate("comment") },
					{ content: userDisplayName(report.user) },
					{ content: humanDate(report.created_at) },
					{
						content: <div style={{ display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'flex-end' }}>
							<CButton style={{ width: 180 }} label={translate("view")} onClick={() => {
								navigate(webPath("/admin/abuse-reports/" + report.id))
							}} />
						</div>
					}
				]}
				pagination={pagination}
				onPageChange={onPageChange}
			/>
			<Toaster />
		</div>
	)
}

export default AbuseReportsList
