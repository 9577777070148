import { CircularProgress } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import CTable from '../../Components/CTable'
import MonthPicker from '../../Components/MonthPicker'
import PageLoading from '../../Components/PageLoading'
import { useUser } from '../../Global/hooks'
import { container } from '../../Global/styles'
import { genericError } from '../../Global/toast'
import { formatPrice, sumGain } from '../../Global/util'
import { getCounters, getCountersForManager } from '../../Network/users'
import { translate } from '../../petal-client-common/Translation'
import { DailyAnalytics } from '../../petal-common/Models/Analytics'

const HomeDashboard = () => {

	const self: {
		month?: number
		year?: number
	} = useMemo(() => {
		return {}
	}, [])

	const user = useUser()
	const pathParams = useParams();
	const pathManagerId = pathParams.id

	const [loading, setLoading] = useState(true)
	const [month, setMonth] = useState(moment().get("month") + 1)
	const [year, setYear] = useState(moment().get("year"))
	const [data, setData] = useState<DailyAnalytics[]>()

	useEffect(() => {
		if (user) {
			loadData(moment().get("month") + 1, moment().get("year"))
		}
	}, [user, pathManagerId])

	const loadData = async (month: number, year: number) => {
		setLoading(true)
		self.month = month
		self.year = year

		try {
			let response
			if (user?.role == "manager" || pathManagerId) {
				console.log("Dashboard of manager")
				response = await getCountersForManager(month, year, user?.id || +pathManagerId!)
			} else {
				response = await getCounters(month, year)
			}
			if (response.data?.month == self.month && response.data.year == self.year) {
				setData(response.data.counters)
			}
		} catch (e) {
			genericError()
		}
		setLoading(false)
	}

	const onMonthChanged = (month: number, year: number) => {
		setMonth(month)
		setYear(year)
		loadData(month, year)
	}

	const onPageChange = () => {

	}

	return (
		<div style={{ ...container, justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
			<div style={{ margin: "20px 0px", display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'stretch' }}>
				<div style={{ marginLeft: 10 }}>
					<MonthPicker month={month} year={year} onMonthChanged={onMonthChanged} />
				</div>
				<div style={{ margin: "0px 20px", display: 'flex' }}>
					<div>
						{Object.entries(sumGain(data || [])).map(([currency, gain]) => (
							<div key={currency} style={{ display: 'flex', fontWeight: 'bold', fontSize: 18 }}>
								<div>{`[${currency}]`}</div>
								<div style={{ marginLeft: 15, width: 200, textAlign: 'end' }}>{translate("gross")}: {formatPrice(gain?.gross || 0)}</div>
								<div style={{ marginLeft: 15, width: 300, textAlign: 'end' }}>{translate("net_from_stores")}: {formatPrice(gain?.net_from_store || 0)}</div>
								<div style={{ marginLeft: 15, width: 200, textAlign: 'end' }}>{translate("net")}: {formatPrice(gain?.net || 0)}</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', flex: 1, alignSelf: 'stretch', overflow: 'auto' }}>
				{
					loading ?
						<PageLoading/>
						:
						<div style={{ display: "flex", flex: 1 }}>
							<CTable<DailyAnalytics>
								data={data || []}
								headerLabels={[
									{ label: translate('day') },
									{ label: translate('flowers') },
									{ label: translate('leds') },
									{ label: translate('gifts') },
									{ label: translate('backgrounds') },
									{ label: translate('gain') }
								]}
								rowCells={(day) => [
									{ content: day.day },
									{
										content: <StatsCell value={day.flowers} freeValue={day.free_flowers} />
									},
									{
										content: <StatsCell value={day.leds} freeValue={day.free_leds} />
									},
									{
										content: <StatsCell value={day.gifts} freeValue={day.free_gifts} />
									},
									{
										content: <StatsCell value={day.wallpapers} freeValue={day.free_wallpapers} />
									},
									{
										content: (
											<div style={{ width: 200 }}>
												{
													Object.entries(day.gains).length == 0 ?
														"-"
														:
														<div>
															{Object.entries(day.gains).map(([currency, gain]) => (
																<div key={currency} style={{ display: 'flex' }}>
																	<div style={{ width: 120 }}>{currency}:</div>
																	<div>{formatPrice(gain?.net || 0)}</div>
																</div>
															))}
														</div>
												}

											</div>
										)
									},
								]}
								onPageChange={onPageChange}
							/>
						</div>
				}
			</div>
		</div>
	)
}

type StatsCellProps = {
	value: number
	freeValue: number
}
const StatsCell = (props: StatsCellProps) => {
	return (
		<div>
			<div style={{ display: 'flex' }}>
				<div style={{ width: 120 }}>{translate("paid")}:</div>
				<div>{props.value || "-"}</div>
			</div>
			<div style={{ display: 'flex' }}>
				<div style={{ width: 120 }}>{translate("free")}:</div>
				<div>{props.freeValue || "-"}</div>
			</div>
		</div>
	)
}

export default HomeDashboard
