import React, { CSSProperties, ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import CModal from '../CModal'
import CTextField from '../CTextField'
import { Checkbox } from '@mui/material'

type DisplayItem = { value: number | string, label: string, has_image?: boolean, image?: string | undefined }

type Props<T> = {
	widget: (onOpen: () => void) => ReactNode
	style?: CSSProperties
	modalTitle?: string
	searchPlaceholder?: string
	index?: number //Serve per lo zindex
	value?: T | null
	values?: T[]
	data: T[]
	data_key: string
	hide_value?: boolean
	multi?: boolean
	mapping: (item: T) => DisplayItem
	onItemSelected?: (item: T) => void
	onMultipleItemsSelected?: (items: T[], selectedAll: boolean) => void
	onClear?: () => void
}
const LargeSetSelect = <T, >(props: Props<T>) => {

	const [modalOpen, setModalOpen] = useState(false)

	const [data, setData] = useState(props.data)
	const [selectedAll, setSelectedAll] = useState(false)
	const [selectedItems, setSelectedItems] = useState<T[]>(props.values || [])

	useEffect(() => {
		
	}, [])

	const onSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const text = e.target.value
		setData(props.data.filter((item => props.mapping(item).label.toLowerCase().includes(text.toLowerCase()))))
	}, [])

	const onCheckChange = (item: T, checked: boolean) => {
		if (checked) {
			setSelectedItems(selectedItems.concat([item]))
		} else {
			setSelectedItems(selectedItems.filter(_item => props.mapping(_item).value != props.mapping(item).value))
		}
	}

	return (
		<div>
			{props.widget(() => setModalOpen(true))}
			<CModal open={modalOpen} title={props.modalTitle} actions={[
				{ label: "Chiudi", onPress: () => setModalOpen(false) },
				{
					label: "Conferma", onPress: () => {
						setModalOpen(false)
						props.onMultipleItemsSelected?.(selectedItems, selectedAll)
					}
				}
			]}>
				<div style={{ minHeight: 500, marginRight: 10 }}>
					<CTextField style={{ width: "100%", marginTop: 10 }} placeholder={props.searchPlaceholder || "Cerca"} onChange={onSearch} />
					{props.multi && <div style={{ marginTop: 20 }}>
						<Checkbox
							checked={selectedAll}
							color={'primary'}
							onChange={(e, checked) => {
								setSelectedAll(checked)
								setSelectedItems(checked ? data : [])
							}}
						/>
						Seleziona tutto
					</div>}

					<div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
						{data.map((item, index) => (
							<div style={{
								display: 'flex',
								alignItems: 'center'
							}} {...(!props.multi ? { onClick: () => props.onItemSelected?.(item) } : {})} key={index}>
								{props.multi && <Checkbox
									checked={!!selectedItems?.find(_item => props.mapping(_item).value == props.mapping(item).value)}
									color={'primary'}
									onChange={(e, checked) => onCheckChange(item, checked)}
								/>}
								{props.mapping(item).label}
							</div>
						))}
					</div>
				</div>
			</CModal>
		</div>
	)
}



export default LargeSetSelect
