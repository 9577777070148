import moment from 'moment'
import React, { useState } from 'react'
import { translate } from '../../petal-client-common/Translation'
import IconButton from '../IconButton'
import * as MDIcons from 'react-icons/md'

type Props = {
	month: number
	year: number
	onMonthChanged: (month: number, year: number) => void
}

const MonthPicker = (props: Props) => {

	const { month, year } = props

	const onNextMonthPress = () => {
		let newMonth = month
		let newYear = year
		
		if(month == 12) {
			newMonth = 1
			newYear ++
		} else {
			newMonth ++
		}

		props.onMonthChanged(newMonth, newYear)
	}

	const onPreviousMonthPress = () => {
		let newMonth = month
		let newYear = year
		
		if(month == 1) {
			newMonth = 12
			newYear --
		} else {
			newMonth --
		}

		props.onMonthChanged(newMonth, newYear)
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<IconButton icon={<MDIcons.MdChevronLeft/>} onClick={onPreviousMonthPress}/>
			<div style={{ margin: "0px 20px", fontSize: 20, fontWeight: 'bold', width: 100, textAlign: 'center' }}>{getMonthLabel(month)} {year}</div>
			<IconButton icon={<MDIcons.MdChevronRight/>} onClick={onNextMonthPress}/>
		</div>
	)
}

const getMonthLabel = (month: number) => {
	switch(month) {
		case 1: return translate("january")
		case 2: return translate("february")
		case 3: return translate("march")
		case 4: return translate("april")
		case 5: return translate("may")
		case 6: return translate("june")
		case 7: return translate("july")
		case 8: return translate("august")
		case 9: return translate("september")
		case 10: return translate("october")
		case 11: return translate("november")
		case 12: return translate("december")
	}
}

export default MonthPicker
