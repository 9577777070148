export default [
	{
		code: "it",
		label: "Italiano"
	},
	{
		code: "en",
		label: "English"
	},
	{
		code: "pt",
		label: "Português"
	}
]