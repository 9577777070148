export const colors = {
	text: "#000",
	buttonText: "#fff",
	background: "#fff",
	primary: "#A6D8EE",
	actions: "#000",
	primaryTransparent: "#A6D8EE55",
	textDisabled: "#555",
	white: "#fff",
	black: "#000",
	lightBlue: "#A6D8EE",
	veryLightBlue: "#E4EFF3",
	link: '#3b5998',
	facebook: '#3b5998',
	grey: "#aaa",
	darkGrey: "#555",
	lightGrey: "#dfdfdf",
	veryLightGrey: "#f5f5f5",
	red: "#FF5A59",
	instagram: "#C13584",
	telegram: "#229ED9",
	whatsapp: "#075E54",
	orange: "#fcba03",
	success: "#ccffcc",
	sidebar: "#343A40",
	selectedItemSidebar: "#6C757D",
}