import React from 'react'
import { colors } from '../../../Global/colors'
import { formatPrice, humanDate, localizedStringValue } from '../../../Global/util'
import { Product } from '../../../petal-common/Models/Product'
import CCard from '../../CCard'
import IconButton from '../../IconButton'
import * as MDIcons from 'react-icons/md'

type Props = {
	product: Product
	onEditClick: (product: Product) => void
	onDeleteClick: (product: Product) => void
}

const ProductCell = (props: Props) => {
	return (
		<CCard style={{ margin: 0, alignSelf: 'stretch', display: 'flex' }}>
			<div style={{ display: 'flex', flex: 1 }}>
				<img style={{ height: 100, width: 100, objectFit: "contain", marginLeft: 10 }} src={props.product.photo ? props.product.photo : '/assets/generic-placeholder.png'} />
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minWidth: 400, marginLeft: 20 }}>
					<div style={{ display: 'flex', alignItems: 'center', alignSelf: 'stretch' }}>
						<div style={{ fontSize: 20, fontWeight: 'bold', flex: 1, textAlign: 'start' }}>{props.product.store_product_id}</div>
						<div style={{ marginLeft: 20 }}>
							<IconButton icon={<MDIcons.MdEdit onClick={() => props.onEditClick(props.product)} />} />
							<IconButton icon={<MDIcons.MdDelete onClick={() => props.onDeleteClick(props.product)} />} />
						</div>
					</div>
					<div style={{ fontSize: 16, marginTop: 5 }}>{localizedStringValue(props.product.name)}</div>
					<div style={{ fontSize: 16, marginTop: 5 }}>Creato il: {humanDate(props.product.created_at)}</div>
				</div>
				<div style={{ height: '100%', width: "1px", backgroundColor: colors.lightGrey, margin: "0px 20px" }} />
				<div style={{ flex: 1, display: 'flex', fontSize: 14 }}>
					{localizedStringValue(props.product.description)}
				</div>
				<div style={{ height: '100%', width: "1px", backgroundColor: colors.lightGrey, margin: "0px 20px" }} />
			</div>
			<div style={{ fontWeight: 'bold', fontSize: 20, minWidth: 150, textAlign: 'end' }}>{props.product.is_free ? "Gratis" : "A pagamento"}</div>
		</CCard>
	)
}

export default ProductCell
