export default {
	'login': "Login",
	'logout': "Logout",
	'register': "Sign up",
	'email': "Email",
	'recover_password': "Recover Password",
	'recover_your_password': "Recover your password",
	'recover_password_instructions': "Enter your email so we can send you instructions on how to retrieve your password",
	'password': "Password",
	'password_confirm': "Confirm Password",
	'register_to_petal': "Sign up for Petal",
	'register_manager': "Register your agency",
	'register_description': "Create an account on Petal and visit all your loved ones. \n With Petal you can keep all your wills alive.",
	'register_description_manager': "Create an account on Petal for your agency. This way you can collaborate with us",
	'register_account_description': "Please enter the following information in order to create your account within Petal.",
	'welcome_to_petal': "We welcome you to Petal",
	'login_description': "Enter your registration email and password",
	'tos_privacy_title': "Licence Agreement",
	'tos_privacy_message': "Within Petal, we are very attached to appropriate community behaviour and the protection of user privacy.",
	'sign_up_with_apple': "Sign up with Apple",
	'sign_up_with_facebook': "Sign up with Facebook",
	'sign_up_with_google': "Sign up with Google",
	'sign_up_with_email': "Sign up with email",
	'sign_in_with_apple': "Login with Apple",
	'sign_in_with_facebook': "Login with Facebook",
	'sign_in_with_google': "Login with Google",
	'sign_in_with_email': "Login with email",
	'or_login_with': "Or log in via social networks",
	'continue': "Continue",
	'forgot_password': "Forgot password",
	'reset_password_sent_title': "Email sent",
	'reset_password_sent_message': "We have sent you an email with instructions on how to recover your password",
	'invalid_data': "Incorrect information",
	'password_not_matching': "The passwords you typed in do not match",
	'error': "Operation failed",
	'email_in_use': "The email address you entered is already in use. If you can't remember your password try to retrieve it",
	'invalid_email': "The email address you entered is invalid. Please try again with another address",
	'invalid_info': "The information entered is not valid. Please try again",
	'missing_data': "Missing data",
	'insert_required_fields': "Please enter all fields marked as mandatory (*)",
	'weak_password': "The password entered is too weak. Try again with a more complex password containing at least 6 characters",
	'wrong_credentials': "The credentials you provided are incorrect. Please try again",
	'user_not_found': "We found no match with the email entered. Try another address",
	'generic_error': "An error occurred, please try again",
	'select_account_type': "Select Account Type",
	'user': "User",
	'manager': "Manager",
	'edit_profile': "Edit profile",
	'first_name': "Name",
	'last_name': "Last name",
	'gender': "Sex",
	'birth_date': "Date of birth",
	'day': 'Day',
	'month': 'Month',
	'year': "Year",
	'residence': "Residence",
	'address': "Address",
	'house_number': "House number",
	'city': "City",
	'post_code': "Zip code",
	'male': "Man",
	'female': "Woman",
	'other': "Other",
	'save': "Save",
	'select_country': "Select country",
	'select': "Select",
	'not_inserted': "Not entered",
	'country': "Nation",
	'tos': "Terms and Conditions",
	'privacy_policy': "Privacy policy",
	'tos_declare': "I declare that I have read and accept the",
	'privacy_declare': "I declare that I have read and accept the",
	'home': "Home",
	'search': "Search",
	'my_people': "My dear ones",
	'favourites': "Favourites",
	'testament': "Will",
	'add': "Add",
	'death_day': "Date of death",
	'cemetery': "Cemetery",
	'cemetery_placeholder': "City / country",
	'no_my_people': "You haven't added any loved ones yet",
	'no_favourites': "You have not yet added anyone to your favourites",
	'no_diary': "You still have nothing to display in the diary",
	'search_placeholder': "Search...",
	'no_results': "No results",
	'no_notifications': "No notifications",
	'niche_in_gallery': 'Loculus in the tunnel',
	'niche_in_family_tomb': "Loculus in the family tomb",
	'niche_cinerary_urn': "Cinerary urn/ ossarium loculus",
	'ground_tomb': 'Grave in the ground',
	'common_ossary': 'Common charnel house',
	'urn_in_family': 'Urn in care of relatives',
	'spread_common_cinerary': "Dispersal in common cinerary",
	'spread_garden': "Scattering in the garden of remembrance",
	'spread_nature': "Dispersion in nature",
	'tomb_type': "Grave type",
	'add_person': "Add a loved one",
	'edit': "Edit",
	'delete': "Delete",
	'confirm_delete': "Delete confirmation",
	'delete_person_warning': "Are you sure you want to remove your loved one from Petal? This action cannot be undone.",
	'select_gender': "Select gender",
	'cancel': "Cancel",
	'if_manager': "If you are an agency",
	'ok': "Ok",
	'tax_id': "VAT number",
	'iban': "IBAN",
	'store': "Store",
	'flowers': "Flowers",
	'leds': "Led",
	'gifts': "Gifts",
	'messages': "Messages",
	'photos': "Photos",
	'photo': "Photo",
	'videos': "Videos",
	'video': "Video",
	'backgrounds': "Backgrounds",
	'write_a_message': "Write a message for your loved one",
	'send_a_photo': "Send a photo to your loved one",
	'send_a_video': "Send a video to your loved one",
	'send_a_audio': "Send an audio to your loved one",
	'send': "Send",
	'write_your_message': "Write your message...",
	'missing_authorization': "Missing authorisation",
	'missing_authorization_camera': "You have not provided permission to access the camera. We need this permission to complete the operation",
	'missing_authorization_generic': "You have not provided the necessary authorisations for the functionality you are trying to use. Go to settings and provide the necessary permissions to Petal, then try again.",
	'go_to_settings': "Go to settings",
	'add_description': "Add description",
	'action_sent': "Gift sent",
	'action_received': "Your loved one received your gift!",
	'return_to_person': "Go back to your dear",
	'uploading': "Upload in progress",
	'comment': "Comment",
	'comments': "Comments",
	'show_all': "See more",
	'tomb_info': "Grave information",
	'write_comment': "Write a comment...",
	'no_comments': "There are no comments to display",
	'share': "Share",
	'edit_email': "Edit email",
	'sure_to_edit_mail': "Are you sure you want to change your email? The email is used to log in",
	'insert_new_email': "Enter your new email here",
	'insert_password': "Enter your account password",
	'edit_password': "Change Password",
	'old_password': "Old password",
	'new_password': "New Password",
	'share_quote_message': "I sent a message to {{name}}, thanks to Petal, download it too and see for yourself. Enter your loved ones now, they will always be in your hands, and you will be able to remember them at any time of the day or night, and from wherever you are, in a shared way and with just one click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_photo': "I sent a photo to {{name}}, thanks to Petal, download it too and check it out. Enter your loved ones now, they will always be in your hands, and you will be able to remember them at any time of the day or night, and from wherever you are, in a shared way and with just one click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_video': "I sent a video to {{name}}, thanks to Petal, download it too and go see it. Enter your loved ones now, they will always be in your hands, and you will be able to remember them at any time of the day or night, and from wherever you are, in a shared way and with just one click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_flower': "I laid a flower at {{name}}, thanks to Petal, download it too and see for yourself. Enter your loved ones now, they will always be in your hands, and you will be able to remember them at any time of the day or night, and from wherever you are, in a shared way and with just one click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_leds': "I lit a led at {{name}}, thanks to Petal, download it too and check it out. Enter your loved ones now, they will always be in your hands, and you will be able to remember them at any time of the day or night, and from wherever you are, in a shared way and with just one click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_gifts': "I made a gift to {{name}}, thanks to Petal, download it too and check it out. Enter your loved ones now, they will always be in your hands, and you will be able to remember them at any time of the day or night, and from wherever you are, in a shared way and with just one click.\nDownload -> https://app.petalsocial.com/download",
	'share_quote_comment': "I commented on a tribute to {{name}}, thanks to Petal, download it too and check it out. Enter your loved ones now, they will always be in your hands, and you will be able to remember them at any time of the day or night, and from wherever you are, in a shared way and with just one click.\nDownload -> https://app.petalsocial.com/download",
	'group_tribute': "Group gift",
	'all_people': "Everybody",
	'all': "All",
	'men': "Men",
	'women': "Women",
	'tribute_for': "A gift to: {{group}}",
	'delete_account': "Delete my account",
	'sure_to_delete_account': "Are you sure you want to delete your account?",
	'delete_account_description': "If you decide to delete your account, all data relating to it will be deleted from our systems. The operation is irreversible.",
	'insert_password_to_procede': "To proceed, enter your account password",
	'assign_activators': "Activator assignment",
	'activator_invite_acceptance': "Acceptance of other people's wishes",
	'prepare_your_later': "Prepare your afterwards",
	'communication_to_social': "Social Communications",
	'audio': "Audio",
	'audios': "Audio",
	'activators': "Activators",
	'will_acceptance': "Will acceptance",
	'activators_explanation': "Caution: Before you start, associate to your profile the 5 persons who will have to confirm your disappearance. These people will receive an e-mail in which they will have to accept your will. Your disappearance will only be confirmed when at least 3 of these 5 people enter Petal to confirm it. Petal will ask the activators with the closest degree of kinship for a death certificate to confirm the authenticity of the disappearance.\nYou can cancel this action at any moment from your profile.",
	'email_preview': "Email preview",
	'activators_assignment': "Activator assignment",
	'search_in_petal': "Search in Petal",
	'search_in_petal_or_email': "Search in Petal or enter your email",
	'select_family_bond': "Select the bond",
	'edit_family_bond': "Modify the bond",
	'parent': "Parent",
	'sibiling': "Brother/Sister",
	'friend': "Friend",
	'relative': "Related",
	'son': "Son/daughter",
	'remove': "Remove",
	'camera': "Camera",
	'library': "Library",
	'remove_photo': "Remove photo",
	'refresh': "Update",
	'invite_with_email': "Invite by email",
	'invite_with_email_description': "Invite people to join Petal, so you can add them as your activators. To invite them, enter their emails here, separated by commas.",
	'invite': "Invite",
	'insert_email': "Enter email",
	'activator_requests': "Activation Requests",
	'activator_request': "Activation request",
	'activator_for': "Activator for",
	'activator_request_rejected': "Activation request refused",
	'got_it': "Got it",
	'activator_requests_explanation': "An acquaintance of yours has nominated you to declare his or her death. By accepting this request you together with four other named persons will ascertain the death of a loved one. The applicant will receive an e-mail with your acceptance.",
	'change_choice': "Change choice",
	'accept_request': "Accept request",
	'reject_request': "Reject request",
	'pending': "Pending",
	'accepted': "Accepted",
	'rejected': "Refuse",
	'dead_process': "Missing notification",
	'dead_process_warning_title': "Attention, are you sure you want to notify the disappearance of {{name}}?",
	'dead_process_explanation': "If you start the disappearance notification process, all other activators will receive a notification.",
	'confirm_death': "Confirmation of disappearance",
	'dead_process_in_progress': "Confirmation of disappearance in progress",
	'your_death_notified_title': "You have been notified of your disappearance",
	'your_death_notified_explanation': "Some of your activators have notified you of your disappearance, if this is incorrect information you can cancel the process with the button below",
	'cancel_death': "Cancel disappearance",
	'death_confirmed': "Disappearance confirmed",
	'max_activators_reached_title': "Maximum number reached",
	'max_activators_reached_message': "The maximum number of activators you can add is {{value}}.",
	'cant_complete_operation': "Unable to complete operation",
	'same_email': "You cannot add a user with the same e-mail address as yourself as an activator.",
	'received_plural': "Received",
	'sent_plural': "Sent",
	'received_plural_female': "Receipts",
	'sent_plural_female': "Send",
	'leave_a_message': "Leave a message",
	'leave_a_photo': "Leave a photo",
	'leave_a_video': "Leave a video",
	'leave_a_audio': "Leave an audio",
	'select_recipient': "Choose recipient",
	'message': "Message",
	'message_from': "Message from",
	'photo_from': "Photos from",
	'video_from': "Video from",
	'audio_from': "Audio from",
	'testament_message_explanation': "You will only be able to read this message when the contributor has passed away",
	'testament_photo_explanation': "You will only be able to view this photo when the donor disappears",
	'testament_video_explanation': "You will only be able to view this video once the donor has disappeared",
	'testament_audio_explanation': "You will only be able to listen to this audio when the donor disappears",
	'testament_messages_guide': "Write messages, assign them to the people you want. At the time of your death, your heirs will be notified that they will find a private message from you in your profile. In this section you see notifications of messages sent to you that you will be able to read in the future.",
	'testament_audio_guide': "Record audio messages, assign them to the people you want. At the time of your death, your heirs will receive notification of a private audio message from you. In this section you see notifications of messages sent to you that you will be able to read in the future.",
	'testament_video_guide': "Record video messages, assign them to the people you want. At the time of your death, heirs will receive a notification of a private audio message from you. In this section you see notifications of messages sent to you that you will be able to read in the future.",
	'testament_photo_guide': "Upload photos, assign them to the people you want. Upon your death, your heirs will receive a notification that you have left photos. In this section you see notifications of messages sent to you that you will be able to read in the future.",
	'record': "Register",
	'stop_record': "Stop",
	'play_record': "Play",
	'pause_record': "Break",
	'view': "View",
	'delete_testament_item_warning': "Are you sure you want to delete this bequest? This operation cannot be undone.",
	'delete_my_later_item_warning': "Are you sure you want to delete this audio message? This operation cannot be undone.",
	'message_to': "Message to",
	'photo_to': "Photo a",
	'video_to': "Video a",
	'audio_to': "Audio a",
	'my_later': "My later",
	'enabled_my_later': 'Enable My later and publish my profile when I disappear',
	'add_social': "Add social",
	'social_name': "Name of social",
	'my_later_explanation': "Prepare your profile for when you are no longer there, leave a voice message for all the people who will drop by to visit you. Your Later will be activated when at least 3 of the 5 people you have chosen in the activator section confirm your disappearance. If this is not the case, you can cancel the activation at any time.",
	'audio_messages': "Audio Messages",
	'profile_management': "Profile Management",
	'profile_manager': "Profile administrator",
	'my_later_audios_explanation': "Record an audio, it can be assigned to one person or open to everyone. Every time someone visits you it will be played automatically",
	'leave_a_audio_in_your_profile': "Leave an audio on your profile",
	'cant_continue_because_you_are_dead': "You cannot continue because your profile is registered as deceased, so this information cannot be changed.",
	'my_later_admin_explanation': "Nominate someone who can edit your profile when you are no longer here. If no one is nominated, no user will be able to edit it.",
	'select_user_or_email': "Select user or email",
	'social_delete_will_guide': "Record a video with your wishes in case of disappearance. Select the social networks to which you want it to be communicated. Petal will take care of reporting your will at the time of your disappearance.",
	'user_type_not_supported': "User type not supported",
	'dashboard': "Dashboard",
	'users': "Users",
	'people': "Deceased",
	'unlock': "Unlock",
	'block': "Lock",
	'account_type': "Account type",
	'creation_date': "Date of creation",
	'percentage_applied': "Percentage applied",
	'creator': "Creator",
	'approve': "Approve",
	'reject': "Reject",
	'percentage_message': "Enter the percentage that the manager will collect from purchases for the deceased that he/she manages.",
	'flowers_top_disclaimer': "The flowers last {{duration}} days and will be visible on your loved one's profile until they wilt",
	'leds_top_disclaimer': "The leds last for {{duration}} days and will be visible on your loved one's profile until they turn off",
	'gifts_top_disclaimer': "Gifts last {{duration}} days and will be visible on your loved one's profile",
	'complete_purchase': "Complete purchase",
	'send_to': "Send to {{name}}",
	'free': "Free",
	'wallpapers_shop': "Shop backgrounds",
	'active': "Active",
	'what_to_say': "What you have to say",
	'social_delete_speech_1': "Me",
	'social_delete_speech_2': "“First and last name“ \n",
	'social_delete_speech_3': "born in",
	'social_delete_speech_4': "“City and Country“\n",
	'social_delete_speech_5': "il",
	'social_delete_speech_6': "“Date of Birth“.\n",
	'social_delete_speech_7': "I send this video to the administrators of",
	'social_delete_speech_8': "“Social Media“",
	'social_delete_speech_9': "via Petal Social, to inform them of my death, inviting them to close my account opened with these details:\n",
	'social_delete_speech_10': "user name __________\npassword __________.\n",
	'social_delete_speech_11': "The authenticity of the video can be verified by observing the functions of petal social and how it is created.\n I thank you, believing that I have thus contributed, with a certified and suitable document, to the draining of the many still open accounts of deceased persons.",
	'retry': "Try again",
	'waiting_for_approve': "Awaiting approval",
	'manager_waiting_text': "We are currently examining your application. Wait for our staff to approve your account",
	'screenshot_disclaimer': "Take a screenshot at this page so you can print this text and then read it while recording the video",
	'item_saved_in_gallery': "Item saved in the gallery",
	'paid': "Paid",
	'gain': "Earnings",
	'gross': "Gross",
	'net_from_stores': "Net from stores",
	'net': "Net",

	'january': "January",
	'february': "February",
	'march': "March",
	'april': "April",
	'may': "May",
	'june': "June",
	'july': "July",
	'august': "August",
	'september': "September",
	'october': "October",
	'november': "November",
	'december': "December",

	'start': 'Start',

	'tutorial_item_welcome_title': 'Welcome to Petal.',
	'tutorial_item_welcome_message': 'Petal Social is the ideal environment for remembering, in a shared way, loved ones who have passed away.',
	'tutorial_item_home_title': 'Your Home Feed.',
	'tutorial_item_home_message': 'Remain updated on every action sent to your loved ones in the home feed.',
	'tutorial_item_search_title': 'Search.',
	'tutorial_item_search_message': 'Search the deceased people in our database.',
	'tutorial_item_add_deceased_title': 'Add your deceased people.',
	'tutorial_item_add_deceased_message': 'Insert in the app the info of your loved ones. You will create a special place to remembering them.',
	'tutorial_item_favourites_title': 'Favourites.',
	'tutorial_item_favourites_message': 'Flag deceased people as ‘Favourite’ to have a simpler access to their page through this section.',
	'tutorial_item_profile_title': 'Your Profile.',
	'tutorial_item_profile_message': 'Configure your profile and the other services linked to it, like MyLater.',

	'notifications': 'Notifications',
	"read_all": "Read all",

	'delete_action': "Delete",
	'report_abuse': "Report abuse",
	'tell_us_why_reporting_action': "Explain us why you are reporting this post",
	'tell_us_why_reporting_comment': "Explain us why you are reporting this comment",
	'report_abuse_received': "Thank you for your contribution, we will analyze your report",
	'report_entity': "Reported element",
	'reporter': "Reporter",
	'report_date': "Report date",
	'post': "Post",
	'delete_action_warning': "Are you sure that you want to delete this post? This operation cannot be reverted.",
	'delete_comment_warning': "Are you sure that you want to delete this comment? This operation cannot be reverted.",

	'name': "Name",
	'description': "Description",
	'pinned': "Pinned",
	'yes': "Yes",
	'no': "No",
	'commemorate': "Commemorate",
	'action_received_by_page': "Thank you for your tribute!",
	'write_a_message_to_page': "Write a message",
	'send_a_photo_to_page': "Send a photo",
	'send_a_video_to_page': "Send a video",
	'return_to_page': "Return to page",

	'free_flowers_available_message': "You have {{value}} free elements from the store, what are you waiting?"
}