import LoginPage from "../Pages/LoginPage";
import { RouteObject } from "react-router-dom"
import AdminRouter from "../Pages/Admin";
import ManagerRouter from "../Pages/Manager";

export const rootNavigation: RouteObject[] = [
	{
		path: "/",
		element: <LoginPage/>
	},
	{
		path: "/admin/*",
		element: <AdminRouter/>
	},
	{
		path: "/manager/*",
		element: <ManagerRouter/>
	}
]