import { http, loadUrl } from "."
import { Person } from "../petal-common/Models/Person"

export type GetPeopleParams = {
	user_id?: number
	limit?: number
	offset?: number
	query?: string
	order_by?: string
	with_relative?: boolean,
	order_mode?: 'ASC' | 'DESC'
}

type GetPeopleResponse = {
	people: Person[]
	people_count: number,
	rows: number,
}

export const getPeople = (params: GetPeopleParams) => {
	return http<GetPeopleResponse>(loadUrl("people", params))
}

export type SetRelativeParams = {
	user_id: number
	person_id: number
}

export const setRelative = (params: SetRelativeParams) => {
	return http(`/people/${params.person_id}/relative`, "PUT", params)
}