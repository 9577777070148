import { http, loadUrl } from "."
import { AbuseReport, AbuseReportStatus } from "../petal-common/Models/AbuseReport"

export type GetAbuseReportsResponse = {
	abuse_reports: AbuseReport[],
	rows: number
	count: number
}
export const getAbuseReports = (limit: number, offset: number) => {
	return http<GetAbuseReportsResponse>(loadUrl("abuse-reports", {
		limit,
		offset
	}))
}

export type AbuseReportResponse = {
	abuse_report: AbuseReport
}
export const getAbuseReport = (report_id: number) => {
	return http<AbuseReportResponse>(`abuse-reports/${report_id}`)
}

export const updateAbuseReportStatus = (report_id: number, status: AbuseReportStatus) => {
	return http<AbuseReportResponse>(`abuse-reports/${report_id}`, "PUT", {
		status
	})
}

export const deleteReportedElement = (report_id: number) => {
	return http<any>(`abuse-reports/${report_id}/delete-element`, "DELETE")
}